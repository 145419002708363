import { Button, Flex, Image, Menu, MenuButton } from "@chakra-ui/react";
import { IconChevronDown } from "@tabler/icons-react";
import React from "react";
import CustomText from "../text/CustomText";

const SelectCoin = ({ img, onClick }: any) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<IconChevronDown width={"16px"} />}
        colorScheme="blackAlpha"
        bg="#272727"
        color="white"
        _hover={{ bg: "#333333" }}
        _expanded={{ bg: "#333333" }}
        // px={4}
        w={"114px"}
        h={"38px"}
        borderRadius="30px"
        px={"0"}
        pl={"4px"}
        pr={"10px"}
        onClick={onClick}
      >
        <Flex align={"center"}>
          <Image src={img} boxSize="32px" mr={2} />
          <CustomText text="BTC" size="12px" lh="120%" />
        </Flex>
      </MenuButton>
    </Menu>
  );
};

export default SelectCoin;
