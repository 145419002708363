import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import DashboardLayout from "./layouts/DashboardLayout";
import AuthLayout from "./layouts/AuthLayout";
import ForgotLayout from "./layouts/forgotLayout";
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/forgotPassword";
import Dashboard from "./pages/dashboard";
import { routes } from "./routes";

function App() {
  const location = useLocation();
  console.log("🚀 ~ App ~ location:", location, routes);

  return (
    <Routes>
      {routes.map((route, index) =>
        route?.isPrivate ? (
          <Route key={index} path="/" element={<DashboardLayout />}>
            <Route path={route.path} element={<route.component />} />
            <Route path="*" element={<Dashboard />} />
          </Route>
        ) : location.pathname === "/auth/forgot-password" ||
          location.pathname === "/auth/forgot-password/verify-email" ||
          location.pathname === "/auth/forgot-password/update-password" ? (
          <Route key={index} path="/auth" element={<ForgotLayout />}>
            <Route path={route.path} element={<route.component />} />
            <Route path="*" element={<ForgotPassword />} />
          </Route>
        ) : (
          <Route key={index} path="/auth" element={<AuthLayout />}>
            <Route path={route.path} element={<route.component />} />
            <Route path="*" element={<Login />} />
          </Route>
        )
      )}
    </Routes>
  );
}

export default App;
