import React, { useState } from "react";
import {
  Step,
  StepIndicator,
  StepNumber,
  StepStatus,
  Stepper,
  Box,
  Flex,
  Stack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import CustomText from "../../components/text/CustomText";

import { useTranslation } from "react-i18next";
import Enteremail from "./EnterEmail";
import VerifyEmail from "./VeriifyEmail";
import UpdatePassword from "./UpdatePassword";

const steps = [
  { title: "Enter Email" },
  { title: "Verify your email" },
  { title: "Update Password" },
];
const ForgotPassword = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const handleNextStep = () => {
    switch (activeStep) {
      case 0:
        setActiveStep(1); // Move to Verify Email step
        navigate("/auth/forgot-password/verify-email"); // Navigate to Verify Email
        break;
      case 1:
        setActiveStep(2); // Move to Update Password step
        navigate("/auth/forgot-password/update-password"); // Navigate to Update Password
        break;
      case 2:
        // Optionally, you can reset or do nothing here if it's the last step
        break;
      default:
        break;
    }
  };
  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return <Enteremail onNext={handleNextStep} />;
      case 1:
        return <VerifyEmail onNext={handleNextStep} />;
      case 2:
        return <UpdatePassword />;
      default:
        return null;
    }
  };
  return (
    <Flex
      minW={{ base: "358px", md: "722px" }}
      flexDir="column"
      alignItems="center"
      gap={"80px"}
      w={"100%"}
      borderRadius={{ base: "16px", md: "none" }}
      bg={{ base: "rgba(255,255,255,0.03)", md: "none" }}
      backdropFilter={{ base: "blur(28px)", md: "none" }}
      p={{ base: "24px 16px", md: "0" }}
    >
      {/* Stepper navigation */}
      <Flex
        minW={{ base: "358px", md: "722px" }}
        justify={{ base: "center" }}
        align={"center"}
        width="100%"
        ml={{ base: "30px", md: "0px" }}
      >
        <Stepper index={activeStep}>
          {steps.map((step, index) => (
            <Stack>
              <Flex
                key={index}
                alignItems="center"
                justifyContent={{
                  base: index === 2 ? "left" : "center",
                  md: "center",
                }}
              >
                <Step>
                  <StepIndicator
                    sx={{
                      "[data-status=complete] &": {
                        background: "extsy.primary",
                        color: "extsy.bg",
                      },
                      "[data-status=active] &": {
                        background: "extsy.primary",
                        color: "extsy.bg",
                      },
                      "[data-status=incomplete] &": {
                        background: "extsy.neutral1000",
                        color: "extsy.neutral300",
                      },
                    }}
                    fontFamily={"Metropolis"}
                    fontSize={"12px"}
                    fontWeight={"700"}
                    border={"none"}
                    mr={{ base: index !== 2 ? "10px" : "0px", md: "0px" }}
                    w={{ base: "24px", md: "32px" }}
                    h={{ base: "24px", md: "32px" }}
                  >
                    <StepStatus
                      complete={<StepNumber />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>

                  <Box
                    flexShrink="0"
                    mx="10px"
                    display={{ base: "none", md: "block" }}
                  >
                    <CustomText
                      text={t(step.title)}
                      size={{ base: "12px", md: "16px" }}
                      fontFamily={"Metropolis"}
                      color={
                        index === activeStep
                          ? "extsy.baseWhite"
                          : "extsy.neutral600"
                      }
                    />
                  </Box>
                </Step>

                {index < steps.length - 1 && (
                  <ActiveBorder
                    bg={
                      index < activeStep ? "extsy.primary" : "extsy.neutral300"
                    }
                    stepTitle={step.title}
                  />
                )}
              </Flex>
              <Box
                flexShrink="0"
                ml={"-20px"}
                display={{ base: "block", md: "none" }}
              >
                <CustomText
                  text={t(step.title)}
                  size={{ base: "12px", md: "16px" }}
                  fontFamily={"Metropolis"}
                  color={
                    index === activeStep
                      ? "extsy.baseWhite"
                      : "extsy.neutral600"
                  }
                />
              </Box>
            </Stack>
          ))}
        </Stepper>
      </Flex>

      {/* Render the corresponding component based on the active step */}
      <Box mt="40px" maxW="400px">
        {renderStepContent()}
      </Box>
    </Flex>
  );
};
const ActiveBorder = ({ bg, stepTitle }: any) => {
  return (
    <Box
      bg={bg}
      w={{ base: "62px", md: `86px` }}
      h="2px"
      borderRadius="full"
    ></Box>
  );
};

export default ForgotPassword;
