import { Button } from "@chakra-ui/react";
import React from "react";

function PrimaryButton({
  title,
  height,
  width,
  maxW,
  onClick,
  isLoading,
  isDisabled,
  padding,
  family,
  size,
  icon,
  icon2,
  weight,
  bg,
  color,
}: any) {
  return (
    <Button
      height={height || "56px"}
      width={width || "100%"}
      maxW={maxW || "100%"}
      onClick={onClick}
      isLoading={isLoading}
      isDisabled={isDisabled}
      padding={padding ? padding : ""}
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap="10px"
      borderRadius="999px"
      bg={bg ? bg : "extsy.g1"}
      _hover={{
        bg: bg || "extsy.g1",
      }}
      fontFamily={family || "Titillium Web"}
      fontSize={size || "16px"}
      fontStyle={weight || "normal"}
      color={color || "extsy.bg"}
    >
      {icon} {title} {icon2}
    </Button>
  );
}

export default PrimaryButton;
