import { Box, Flex, Stack, useMediaQuery } from "@chakra-ui/react";
import React, { useState } from "react";
import { LoginPayload } from "../../utils/types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomText from "../../components/text/CustomText";
import GradientText from "../../components/text/GradientText";
import PrimaryButton from "../../components/button/primaryButton";
import AuthCustomInput from "../../components/form/AuthCustomInput";
import EyeIcon from "../../assets/images/eye.svg";

function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLessThan768] = useMediaQuery("(max-height: 799px)");
  const [payload, setPayload] = useState<LoginPayload>({
    email: "",
    password: "",
  });
  const [errors] = useState({
    email: "",
    password: "",
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <Flex
      px={{ base: "20px", md: "40px" }}
      py={isLessThan768 ? "40px" : "56px"}
      mx="auto"
      bg="rgba(255, 255, 255, 0.03)"
      borderRadius="24px"
      maxW={{ base: "368px", md: "480px" }}
      w="100%"
      flexDir="column"
      justify="center"
      align={"center"}
      gap={isLessThan768 ? "24px" : "0px"}
      minH={isLessThan768 ? "424px" : "742px"}
    >
      <CustomText
        text={t("Login to Partner Dashboard")}
        color="extsy.baseWhite"
        family="Power Grotesk"
        size="24px"
        align="center"
      />
      <Stack gap={"8px"} w={"100%"} align={"flex-start"} mt={"22px"}>
        <CustomText
          text="Your email"
          color="extsy.neutral500"
          size="12px"
          lh="120%"
        />
        <AuthCustomInput
          placeholder={t("Enter your email")}
          type="email"
          color={
            payload.email === "jhond@gmail.com" || errors?.email
              ? "#ED3D4E"
              : "extsy.text"
          }
          value={payload.email}
          onChange={(e: any) =>
            setPayload({ ...payload, email: e.target.value })
          }
        />
      </Stack>
      <Stack gap={"8px"} w={"100%"} my={"22px"} align={"flex-start"}>
        <Flex align={"center"} gap={"8px"}>
          <CustomText
            text="Password"
            color="extsy.neutral500"
            size="12px"
            lh="120%"
          />
        </Flex>

        <AuthCustomInput
          placeholder={t("Enter your password")}
          type={isPasswordVisible ? "text" : "password"}
          value={payload.password}
          rightIco={EyeIcon}
          onIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
          onChange={(e: any) =>
            setPayload({ ...payload, password: e.target.value })
          }
        />
        <Flex justify={"flex-end"} w={"100%"}>
          <GradientText
            text={t("forgotPassword")}
            cursor="pointer"
            bg="extsy.g1"
            lh="19px"
            mt="12px"
            align="right"
            onClick={() => {
              navigate("/auth/forgot-password");
            }}
          />
        </Flex>
      </Stack>

      <Flex flexDir="column" gap="24px" align="center" w={"100%"}>
        <Box mt={{ base: "90px", md: "0px" }} w={"100%"}>
          <PrimaryButton
            title={t("signIn")}
            width="100%"
            onClick={() => {
              navigate("/auth/security-code");
            }}
          />
        </Box>
        <Flex align="center" gap="4px">
          <CustomText
            text={t("notRegistered")}
            color="extsy.neutral300"
            lh="19px"
          />
          <GradientText
            text={t("signUp")}
            weight="600"
            bg="extsy.g1"
            lh="22px"
            cursor="pointer"
            onClick={() => {
              navigate("/auth/signup");
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Login;
