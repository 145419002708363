import {
  Avatar,
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../text/CustomText";
import UserPic from "../../assets/images/dummy-user.svg";
import NotificationIco from "../../assets/images/notification-ico.svg";
import LanguageIco from "../../assets/images/language-ico.svg";
import BurgerIco from "../../assets/images/burger-btn.svg";
import { useTranslation } from "react-i18next";
import Notifications from "./Notifications";
import Languages from "./Languages";
import IconMenuClose from "../../assets/images/icon-menu-close.svg";
import { DASHBOARD_MENU, DASHBOARD_OTHER_MENU } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import GradientText from "../text/GradientText";
import LogoutModal from "../modal/LogoutModal";

function Navbar() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isNotificaitonOpen, setIsNotificationOpen] = useState(false);
  const [isLangOpen, setIsLangOpen] = useState(false);
  const {
    isOpen: isMobileMenuOpen,
    onOpen: onMobileMenuOpen,
    onClose: onMobileMenuClose,
  } = useDisclosure();
  const {
    isOpen: isLogoutOpen,
    onOpen: onLogoutOpen,
    onClose: onLogoutClose,
  } = useDisclosure();

  return (
    <Flex gap="24px" w="100%" flexDir="column">
      <Flex
        justify="space-between"
        align="center"
        gap={{ lg: "24px" }}
        w="100%"
      >
        <Box display={{ base: "none", md: "block" }}>
          <CustomText
            text={t("welcomeText")}
            family="Titillium Web"
            size={{ base: "14px", lg: "28px" }}
            weight="700"
            color="extsy.neutral50"
            lh="36px"
          />
        </Box>
        <Flex
          gap="16px"
          p={{ base: "16px", md: "none" }}
          align="center"
          justifyContent={{ base: "space-between", md: "none" }}
          w={{ base: "100%", md: "unset" }}
        >
          <Flex align="center" gap="8px">
            <Avatar
              src={UserPic}
              name="SA"
              boxSize={"48px"}
              bg="extsy.g1"
              color="extsy.bg"
            />
            <Flex flexDir="column" gap="6px">
              <CustomText
                text="Serra Alexandra"
                color="extsy.baseWhite"
                weight="600"
                size={"16px"}
              />
              <Flex align="center" gap="8px">
                {/* <IconShield size="16px" color="#fff" /> */}
                <CustomText
                  text="user00@email.com"
                  size={"12px"}
                  weight="400"
                />
              </Flex>
            </Flex>
          </Flex>
          <Flex align="center" gap="8px">
            <Flex
              flexDir="column"
              justify="center"
              align="center"
              height="48px"
              width="48px"
              bg="extsy.neutral1000"
              borderRadius="99px"
              border="1px solid"
              borderColor="extsy.neutral900"
              cursor="pointer"
              pos="relative"
              display={{ base: "none", md: "flex" }}
              onClick={() => {
                setIsNotificationOpen(false);
                setIsLangOpen(!isLangOpen);
              }}
            >
              <Image src={LanguageIco} alt="lang" boxSize="24px" />
              {isLangOpen && <Languages />}
            </Flex>
            <Flex
              flexDir="column"
              justify="center"
              align="center"
              p={"8px"}
              height={{ base: "34px", md: "48px" }}
              width={{ base: "34px", md: "48px" }}
              bg="extsy.neutral1000"
              borderRadius="99px"
              border="1px solid"
              borderColor="extsy.neutral900"
              cursor="pointer"
              pos="relative"
              onClick={() => {
                setIsNotificationOpen(!isNotificaitonOpen);
                setIsLangOpen(false);
              }}
            >
              <Image src={NotificationIco} alt="notification" boxSize="24px" />
              {isNotificaitonOpen && <Notifications />}
            </Flex>
            <Box display={{ base: "block", md: "none" }}>
              <ActionButton onClick={onMobileMenuOpen} img={BurgerIco} />
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Drawer
        isOpen={isMobileMenuOpen}
        placement="left"
        size="full"
        onClose={() => {}}
      >
        <DrawerOverlay />
        <DrawerContent bg="extsy.neutral1000">
          <DrawerBody p="24px" overflowY="auto">
            <Flex flexDir="column" gap="16px">
              <Flex justify="space-between" gap="16px" align="center" pb="8px">
                <UserProfile isMenu={true} />
                <Flex gap="8px" align="center">
                  <UserLanguages
                    isLangOpen={isLangOpen}
                    setIsLangOpen={setIsLangOpen}
                    setIsNotificationOpen={setIsNotificationOpen}
                  />
                  <ActionButton
                    onClick={onMobileMenuClose}
                    img={IconMenuClose}
                  />
                </Flex>
              </Flex>
              <Divider
                color="extsy.neutral1000"
                borderColor="extsy.neutral1000"
                opacity="1"
              />
              <Stack align={"flex-start"}>
                <CustomText
                  text={t("menu")}
                  color="extsy.neutral200"
                  size="12px"
                  weight="600"
                  ls="0.12px"
                  align={"start"}
                />
                <Flex flexDir="column" mt="8px" width="100%">
                  {DASHBOARD_MENU.map((menu: any, index: any) => (
                    <Flex
                      key={index}
                      gap="8px"
                      align="center"
                      justify={"flex-start"}
                      cursor="pointer"
                      height={"44px"}
                      p={"12px"}
                      width={"232px"}
                      borderRadius="8px"
                      bg={menu.path === pathname ? "extsy.g2" : "transparent"}
                      onClick={() => {
                        navigate(menu.path);
                        onMobileMenuClose();
                      }}
                    >
                      <Image
                        src={
                          pathname === menu?.path
                            ? menu.activeIco
                            : menu.inActiveIco
                        }
                        alt={menu?.name}
                        width="24px"
                        height="24px"
                      />
                      {menu?.path === pathname ? (
                        <GradientText
                          text={t(menu.key)}
                          bg="extsy.bg"
                          cursor="pointer"
                        />
                      ) : (
                        <CustomText
                          text={t(menu.key)}
                          color="extsy.neutral300"
                          cursor="pointer"
                        />
                      )}
                    </Flex>
                  ))}
                </Flex>
              </Stack>
              <Divider
                color="extsy.neutral1000"
                borderColor="extsy.neutral1000"
                opacity="1"
              />
              <Stack>
                <CustomText
                  text={t("others")}
                  // mt="20px"
                  color="extsy.neutral200"
                  size="12px"
                  weight="600"
                  ls="0.12px"
                  align={"start"}
                />
                <Flex flexDir="column" mt="8px" width="100%">
                  {DASHBOARD_OTHER_MENU.map((menu: any, index: any) => (
                    <Flex
                      key={index}
                      gap="8px"
                      align="center"
                      justify={"flex-start"}
                      cursor="pointer"
                      height={"44px"}
                      p={"12px"}
                      width={"232px"}
                      borderRadius="8px"
                      bg={menu.path === pathname ? "extsy.g2" : "transparent"}
                      onClick={() => {
                        if (menu?.name === "Logout") {
                          onLogoutOpen();
                        } else if (menu?.path) {
                          navigate(menu.path);
                          onMobileMenuClose();
                        }
                      }}
                    >
                      <Image
                        src={
                          pathname === menu?.path
                            ? menu.activeIco
                            : menu.inActiveIco
                        }
                        alt={menu?.name}
                        width="24px"
                        height="24px"
                      />
                      {menu?.path === pathname ? (
                        <GradientText
                          text={t(menu.key)}
                          bg="extsy.bg"
                          cursor="pointer"
                        />
                      ) : (
                        <CustomText
                          text={t(menu.key)}
                          bg="extsy.neutral300"
                          cursor="pointer"
                        />
                      )}
                    </Flex>
                  ))}
                </Flex>
              </Stack>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <LogoutModal isOpen={isLogoutOpen} onClose={onLogoutClose} />
    </Flex>
  );
}

export default Navbar;

const UserProfile = ({ isMenu }: any) => {
  return (
    <Flex align="center" gap="8px">
      <Avatar src="" name="SA" boxSize="48px" bg="extsy.g1" color="extsy.bg" />
      <Flex
        flexDir="column"
        gap="6px"
        display={{
          base: isMenu ? "flex" : "none",
          lg: "flex",
        }}
      >
        <CustomText
          text="Serra Alexandra"
          color="extsy.baseWhite"
          weight="600"
        />
        <Flex align="center" gap="8px">
          <CustomText text="user00@gmail.com" size="12px" weight="500" />
        </Flex>
      </Flex>
    </Flex>
  );
};

const UserLanguages = ({
  isLangOpen,
  setIsLangOpen,
  setIsNotificationOpen,
}: any) => {
  return (
    <ActionButton
      onClick={() => {
        setIsNotificationOpen(false);
        setIsLangOpen(!isLangOpen);
      }}
      img={LanguageIco}
      child={isLangOpen && <Languages />}
    />
  );
};

const ActionButton = ({ onClick, img, child }: any) => {
  return (
    <Flex
      flexDir="column"
      justify="center"
      align="center"
      height={{ base: "34px", lg: "48px" }}
      width={{ base: "34px", lg: "48px" }}
      bg="extsy.neutral1000"
      borderRadius="99px"
      border="1px solid"
      borderColor="extsy.neutral900"
      cursor="pointer"
      pos="relative"
      onClick={onClick}
    >
      <Image src={img} alt="menu" boxSize={{ base: "18px", lg: "24px" }} />
      {child}
    </Flex>
  );
};
