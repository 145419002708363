import { Box, Image, Input } from "@chakra-ui/react";
import React from "react";
const AuthCustomInput = ({
  type,
  placeholder,
  value,
  onChange,
  rightIco,
  onIconClick,
  color,
}: any) => {
  return (
    <Box pos="relative" w={"100%"}>
      <Input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        borderBottom="1px solid"
        borderColor="extsy.neutral900"
        borderTop="none"
        borderLeft="none"
        borderRight="none"
        borderRadius="0"
        color={color || "extsy.text"}
        fontSize="16px"
        fontFamily="Titillium Web"
        pl="0"
        pr={rightIco ? "30px" : "auto"}
        _placeholder={{
          color: "extsy.text",
          fontSize: "16px",
          fontWeight: "400",
          fontFamily: "Metropolis",
        }}
        _hover={{
          borderColor: "extsy.secondary",
        }}
        _focusVisible={{
          border: "none",
          borderBottom: "1px solid",
          borderColor: "extsy.secondary",
        }}
      />
      {rightIco && (
        <Box
          pos="absolute"
          right="0"
          top="0"
          mt="8px"
          onClick={onIconClick}
          cursor={"pointer"}
        >
          <Image src={rightIco} alt="icon" />
        </Box>
      )}
    </Box>
  );
};

export default AuthCustomInput;
