import { Box, Flex, Image, Stack, useOutsideClick } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import CustomText from "../text/CustomText";
import CustomInput from "../form/CustomInput";
import CheckedIcon from "../../assets/images/checkbox-checked.svg";
import UnCheckedIcon from "../../assets/images/checkbox-unchecked.svg";
import { useTranslation } from "react-i18next";
import BitcoinIco from "../../assets/images/btc-ico.svg";
import EhereumIco from "../../assets/images/ethereum-icon.svg";
import SolanaIco from "../../assets/images/sol-ico.svg";
import BinanceIco from "../../assets/images/binance-icon.svg";
import { IconCheck, IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import ColorInput from "../form/ColorInput";

const WidgetType = () => {
  const { t } = useTranslation();

  const filterRef = useRef(null);

  const [logoChecked, setLogoChecked] = useState(true);
  const [faqChecked, setFaqChecked] = useState(true);
  const [languageChecked, setLanguageChecked] = useState(true);
  const [fixedRateChecked, setFixedRateChecked] = useState(false);
  const [darkthemeChecked, setDarkThemeChecked] = useState(true);
  const [activeFilter, setActiveFilter] = useState("English");
  const [activeFilterFrom, setActiveFilterFrom] = useState("BTC");
  const [activeFilterTo, setActiveFilterTo] = useState("ETH");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isFilterOpenFrom, setIsFilterOpenFrom] = useState(false);
  const [isFilterOpenTo, setIsFilterOpenTo] = useState(false);

  const FILTER_ITEMS = [
    {
      id: "english",
      name: t("English"),
    },
    {
      id: "spanish",
      name: t("Spanish"),
    },
    {
      id: "german",
      name: t("German"),
    },
    {
      id: "arabic",
      name: t("Arabic"),
    },
  ];

  const FILTER_ITEMS_FROM = [
    {
      id: "btc",
      name: t("BTC"),
      img: BitcoinIco,
    },
    {
      id: "eth",
      name: t("ETH"),
      img: EhereumIco,
    },
    {
      id: "SOL",
      name: t("Solana"),
      img: SolanaIco,
    },
    {
      id: "binance",
      name: t("BNB"),
      img: BinanceIco,
    },
  ];

  const FILTER_ITEMS_TO = [
    {
      id: "btc",
      name: t("BTC"),
      img: BitcoinIco,
    },
    {
      id: "eth",
      name: t("ETH"),
      img: EhereumIco,
    },
    {
      id: "SOL",
      name: t("Solana"),
      img: SolanaIco,
    },
    {
      id: "binance",
      name: t("BNB"),
      img: BinanceIco,
    },
  ];

  useOutsideClick({
    ref: filterRef,
    handler: () => {
      setIsFilterOpen(false);
    },
  });

  return (
    <Stack
      p={{ base: "16px", md: "32px" }}
      gap={"32px"}
      borderRadius={"24px"}
      border={"1px solid #221f1f"}
      bg="extsy.neutral1000"
      w={"100%"}
    >
      <CustomText
        text="Widget type"
        color="extsy.baseWhite"
        family="Titillium Web"
        size={{ base: "16px", md: "24px" }}
        weight={{ base: "400", md: "700" }}
        lh="24px"
      />
      <CustomInput placeholder="Classic" phColor="extsy.baseWhite" />
      <Flex align={"center"} justify={"space-between"}>
        <Flex align={"center"} gap={"8px"}>
          <Image
            src={logoChecked ? CheckedIcon : UnCheckedIcon}
            alt="checked"
            cursor={"pointer"}
            onClick={() => setLogoChecked(!logoChecked)}
          />
          <CustomText text="Logo" />
        </Flex>
        <Flex align={"center"} gap={"8px"}>
          <Image
            src={faqChecked ? CheckedIcon : UnCheckedIcon}
            alt="checked"
            cursor={"pointer"}
            onClick={() => setFaqChecked(!faqChecked)}
          />
          <CustomText text="FAQ" />
        </Flex>
        <Flex align={"center"} gap={"8px"}>
          <Image
            src={fixedRateChecked ? CheckedIcon : UnCheckedIcon}
            alt="checked"
            cursor={"pointer"}
            onClick={() => setFixedRateChecked(!fixedRateChecked)}
          />
          <CustomText text="Fixed rate" />
        </Flex>
      </Flex>
      <Stack gap={"24px"}>
        <CustomText
          text="Localization"
          color="extsy.baseWhite"
          size={{ base: "14px", md: "18px" }}
          weight="600"
          lh="120%"
        />
        <Flex
          gap={"24px"}
          align={{ base: "flex-start", md: "center" }}
          w={"100%"}
          flexDirection={{ base: "column", md: "row" }}
        >
          <Box pos="relative" w={{ base: "100%", md: "65%" }}>
            <Flex
              height="38px"
              minW="149px"
              align="center"
              justify="space-between"
              cursor="pointer"
              borderRadius="666px"
              bg="extsy.neutral900"
              boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
              ps="24px"
              pe="10px"
              onClick={() => {
                setIsFilterOpen(!isFilterOpen);
              }}
            >
              <CustomText
                cursor="pointer"
                text={t(activeFilter)}
                size="12px"
                color="extsy.baseWhite"
              />
              {isFilterOpen ? (
                <IconChevronUp color="#FAFAFA" size="16px" />
              ) : (
                <IconChevronDown color="#FAFAFA" size="16px" />
              )}
            </Flex>
            {isFilterOpen && (
              <Flex
                flexDir="column"
                gap="12px"
                p="16px"
                borderRadius="16px"
                border="1px solid"
                borderColor="extsy.neutral800"
                bg="extsy.neutral900"
                minW="150px"
                pos="absolute"
                top="0"
                marginTop="50px"
                ref={filterRef}
              >
                {FILTER_ITEMS.map((item, index) => (
                  <Flex
                    align="center"
                    justify="space-between"
                    py="8px"
                    cursor="pointer"
                    key={index}
                    onClick={() => {
                      setIsFilterOpen(false);
                      setActiveFilter(item.id);
                    }}
                  >
                    <CustomText
                      text={item?.name}
                      size="14px"
                      color={
                        item?.id === activeFilter
                          ? "extsy.baseWhite"
                          : "extsy.neutral300"
                      }
                      cursor="pointer"
                    />
                    {item?.id === activeFilter && (
                      <IconCheck size="16px" color="#FAFAFA" />
                    )}
                  </Flex>
                ))}
              </Flex>
            )}
          </Box>
          <Box w={{ base: "100%", md: "35%" }}>
            <Flex align={"center"} gap={"8px"}>
              <Image
                src={languageChecked ? CheckedIcon : UnCheckedIcon}
                alt="checked"
                cursor={"pointer"}
                onClick={() => setLanguageChecked(!languageChecked)}
              />
              <CustomText text="Language Picker" />
            </Flex>
          </Box>
        </Flex>
      </Stack>
      <Stack gap={"24px"}>
        <CustomText
          text="Theme"
          color="extsy.baseWhite"
          size={{ base: "14px", md: "18px" }}
          weight="600"
          lh="120%"
        />
        <Flex
          align={{ md: "center" }}
          justify={"space-between"}
          flexDirection={{ base: "column", md: "row" }}
          w={"100%"}
          gap={{ base: "24px", md: "0px" }}
        >
          <Flex
            align={"center"}
            gap={{ base: "16px", xl: "24px" }}
            w={{ base: "100%", md: "72%" }}
          >
            <ColorInput value="#00C26F" />
            <ColorInput value="#FFFFFF" />
          </Flex>
          <Flex align={"center"} gap={"8px"}>
            <Image
              src={darkthemeChecked ? CheckedIcon : UnCheckedIcon}
              alt="checked"
              cursor={"pointer"}
              onClick={() => setDarkThemeChecked(!darkthemeChecked)}
            />
            <CustomText text="Dark Theme" />
          </Flex>
        </Flex>
      </Stack>
      <Stack gap={"24px"}>
        <CustomText
          text="Default Values"
          color="extsy.baseWhite"
          size={{ base: "14px", md: "18px" }}
          weight="600"
          lh="120%"
        />
        <Stack gap={"16px"}>
          <CustomText text="Default Amount" size="14px" lh="120%" />
          <CustomInput placeholder="0.1" value="0.1" />
        </Stack>
        <Flex
          gap={"16px"}
          align={"center"}
          flexDirection={{ base: "column", md: "row" }}
        >
          <Stack gap={"16px"} w={"100%"}>
            <CustomText text="Default Coin (From)" size="14px" lh="120%" />
            <Box pos="relative">
              <Flex
                height="56px"
                minW="149px"
                align="center"
                justify="space-between"
                cursor="pointer"
                borderRadius="666px"
                bg="extsy.neutral900"
                boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
                ps="24px"
                pe="10px"
                onClick={() => {
                  setIsFilterOpenFrom(!isFilterOpenFrom);
                }}
              >
                <Flex align={"center"} gap={"8px"}>
                  <Image src={BitcoinIco} w={"24px"} />
                  <CustomText
                    cursor="pointer"
                    text={t(activeFilterFrom)}
                    size="12px"
                    color="extsy.baseWhite"
                  />
                </Flex>
                {isFilterOpenFrom ? (
                  <IconChevronUp color="#FAFAFA" size="16px" />
                ) : (
                  <IconChevronDown color="#FAFAFA" size="16px" />
                )}
              </Flex>
              {isFilterOpenFrom && (
                <Flex
                  flexDir="column"
                  gap="12px"
                  p="16px"
                  borderRadius="16px"
                  border="1px solid"
                  borderColor="extsy.neutral800"
                  bg="extsy.neutral900"
                  minW="150px"
                  pos="absolute"
                  top="0"
                  marginTop="50px"
                  ref={filterRef}
                >
                  {FILTER_ITEMS_FROM.map((item, index) => (
                    <Flex
                      align="center"
                      justify="space-between"
                      py="8px"
                      cursor="pointer"
                      key={index}
                      onClick={() => {
                        setIsFilterOpenFrom(false);
                        setActiveFilterFrom(item.id);
                      }}
                    >
                      <Flex align={"center"} gap={"8px"}>
                        <Image src={item?.img} w={"24px"} />
                        <CustomText
                          text={item?.name}
                          size="14px"
                          color={
                            item?.id === activeFilterFrom
                              ? "extsy.baseWhite"
                              : "extsy.neutral300"
                          }
                          cursor="pointer"
                        />
                      </Flex>
                      {item?.id === activeFilterFrom && (
                        <IconCheck size="16px" color="#FAFAFA" />
                      )}
                    </Flex>
                  ))}
                </Flex>
              )}
            </Box>
          </Stack>
          <Stack gap={"16px"} w={"100%"}>
            <CustomText text="Default Coin (To)" size="14px" lh="120%" />
            <Box pos="relative">
              <Flex
                height="56px"
                minW="149px"
                align="center"
                justify="space-between"
                cursor="pointer"
                borderRadius="666px"
                bg="extsy.neutral900"
                boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
                ps="24px"
                pe="10px"
                onClick={() => {
                  setIsFilterOpenTo(!isFilterOpenTo);
                }}
              >
                <Flex align={"center"} gap={"8px"}>
                  <Image src={EhereumIco} w={"24px"} />
                  <CustomText
                    cursor="pointer"
                    text={t(activeFilterTo)}
                    size="12px"
                    color="extsy.baseWhite"
                  />
                </Flex>
                {isFilterOpenTo ? (
                  <IconChevronUp color="#FAFAFA" size="16px" />
                ) : (
                  <IconChevronDown color="#FAFAFA" size="16px" />
                )}
              </Flex>
              {isFilterOpenTo && (
                <Flex
                  flexDir="column"
                  gap="12px"
                  p="16px"
                  borderRadius="16px"
                  border="1px solid"
                  borderColor="extsy.neutral800"
                  bg="extsy.neutral900"
                  minW="150px"
                  pos="absolute"
                  top="0"
                  marginTop="50px"
                  ref={filterRef}
                >
                  {FILTER_ITEMS_TO.map((item, index) => (
                    <Flex
                      align="center"
                      justify="space-between"
                      py="8px"
                      cursor="pointer"
                      key={index}
                      onClick={() => {
                        setIsFilterOpenTo(false);
                        setActiveFilterTo(item.id);
                      }}
                    >
                      <Flex align={"center"} gap={"8px"}>
                        <Image src={item?.img} w={"24px"} />
                        <CustomText
                          text={item?.name}
                          size="14px"
                          color={
                            item?.id === activeFilterTo
                              ? "extsy.baseWhite"
                              : "extsy.neutral300"
                          }
                          cursor="pointer"
                        />
                      </Flex>
                      {item?.id === activeFilterTo && (
                        <IconCheck size="16px" color="#FAFAFA" />
                      )}
                    </Flex>
                  ))}
                </Flex>
              )}
            </Box>
          </Stack>
        </Flex>
      </Stack>
    </Stack>
  );
};

export default WidgetType;
