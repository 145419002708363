import { Box, Flex, Image, Stack } from "@chakra-ui/react";
import React from "react";
import MineCryptoIcon from "../../assets/images/profit_payout.svg";
import CustomText from "../text/CustomText";
import PrimaryButton from "../button/primaryButton";
import horizontalLine from "../../assets/images/hor_line.svg";

const ProfitPayout = ({ onClick }: any) => {
  return (
    <Flex
      p={"32px"}
      borderRadius="24px"
      flexDirection={"column"}
      h={"100%"}
      gap={"24px"}
      bg="extsy.neutral1000"
      align={"center"}
      flexDir="column"
      position={"relative"}
    >
      <Flex w={"100%"}>
        <CustomText
          text={"Ready for payout"}
          size="24px"
          weight="700"
          lh="23.784px"
          color="extsy.baseWhite"
        />
      </Flex>

      <Image
        src={MineCryptoIcon}
        alt="mine-crypto"
        pos="absolute"
        top="20"
        draggable="false"
        // right="0"
        // zIndex="10"
      />
      <Box mt={{ base: "250px", xl: "225px" }}></Box>
      <CustomText
        text={"Available Reward"}
        color="extsy.baseWhite"
        weight="600"
        lh="120%"
      />
      <Stack align={"center"}>
        <Flex align="flex-end" gap="12px" flexWrap={"wrap"}>
          <CustomText
            text={"0.00"}
            size="56px"
            family="Titillium Web"
            ls="2.8px"
            color="extsy.secondary100"
          />
          <Box pb="4px">
            <CustomText
              text={"BTC"}
              size="24px"
              ls="1.2px"
              family="Titillium Web"
              color="extsy.neutral500"
            />
          </Box>
        </Flex>
        <Flex align={"center"} gap={"8px"}>
          <CustomText
            text={"~0.00"}
            size="24px"
            ls="1.2px"
            family="Titillium Web"
            color="extsy.neutral500"
          />
          <CustomText
            text={"USD"}
            size="24px"
            ls="1.2px"
            family="Titillium Web"
            color="extsy.neutral500"
          />
        </Flex>
      </Stack>
      <PrimaryButton title="Request a payout" width="160px" onClick={onClick} />
      <Image src={horizontalLine} alt="line" />
      <Stack gap={"18px"} w={"100%"} align={"flex-start"}>
        <CustomText
          text="Stats"
          weight="600"
          lh="120%"
          color="extsy.baseWhite"
        />
        <Stack gap={"8px"}>
          <CustomText
            text="Total Withdrawal"
            family="Titillium Web"
            size="12px"
          />
          <Flex align={"center"} gap={"2px"}>
            <CustomText
              text="$ 2000.48"
              size="14px"
              weight="600"
              lh="120%"
              color="extsy.baseWhite"
            />
            <CustomText
              text="spent of $10,000"
              size="14px"
              weight="600"
              lh="120%"
              color="extsy.neutral300"
            />
          </Flex>
        </Stack>
      </Stack>
    </Flex>
  );
};

export default ProfitPayout;
