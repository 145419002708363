import { Box, Center, Flex, Image, Stack } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../components/text/CustomText";
import { useTranslation } from "react-i18next";
import CopyIcon from "../../assets/images/document-copy-white.svg";
import PrimaryButton from "../../components/button/primaryButton";
import { useNavigate } from "react-router-dom";

const ApiKey = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);

  return (
    <Center
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      borderRadius={"23px"}
      bg={"rgba(255,255,255,0.04)"}
      backdropFilter={"blur(12px)"}
      maxW={{ base: "358px", md: "480px", lg: "600px" }}
      mb={"60px"}
      gap={"10px"}
      border={"1px solid #4E4949"}
      p={"56px 40px"}
    >
      <Center flexDirection={"column"} gap={"32px"}>
        <CustomText
          text={t("Save your APIKey!")}
          color="extsy.baseWhite"
          family="Power Grotesk"
          size="24px"
        />
        <Center
          p={{ base: "16px", md: "32px" }}
          gap={"10px"}
          borderRadius={"16px"}
          bg={"#0B0A0A"}
        >
          <CustomText
            text={t(
              "Make sure to back up your API Key! Keep it as safely as you would your cryptowallet’s private key."
            )}
            bgGradient="linear-gradient(90deg, #FEF9CC 0%, #F6DBD4 40%, #F1C8D9 75%, #F0C2DB 100%)"
            bgClip="text"
            align="center"
            size={{ base: "14px", md: "16px" }}
          />
        </Center>
        <Stack
          justify={"space-between"}
          align={"flex-start"}
          mt={{ base: "56px", md: "0px" }}
        >
          <CustomText
            text={t("API Key")}
            color="extsy.neutral500"
            size="12px"
            lh="120%"
          />
          <Flex gap={"8px"} alignSelf={"stretch"} align={"flex-start"}>
            <CustomText
              text={t(
                "1032553a38d4118b4681021d03dd337a751026404c60d4ff4760eb27a3824f32"
              )}
              color="extsy.baseWhite"
              wrap="wrap"
              size={{ base: "14px", md: "16px" }}
              wb="break-word"
            />
            <Image
              src={CopyIcon}
              alt="copy"
              w={"24px"}
              cursor={"pointer"}
              onClick={() => {
                navigator.clipboard.writeText(
                  "1032553a38d4118b4681021d03dd337a751026404c60d4ff4760eb27a3824f32"
                );
                setCopied(true);
              }}
            />
          </Flex>
          {copied && (
            <Center
              p={"4px 6px"}
              gap={"10px"}
              borderRadius={"17px"}
              bg={"rgba(196, 255, 245, 0.58)"}
              mx={"auto"}
            >
              <CustomText
                text="API Key copied to clipboard."
                color="#C4FFF5"
                size={{ base: "14px", md: "16px" }}
              />
            </Center>
          )}
        </Stack>
        <Box mt={{ base: "90px", md: "0px" }} w={{ base: "100%", md: "75%" }}>
          <PrimaryButton title="Confirm" onClick={() => navigate("/")} />
        </Box>
      </Center>
    </Center>
  );
};

export default ApiKey;
