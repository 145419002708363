import React from "react";
import CustomModal from "./customModal";
import { useNavigate } from "react-router-dom";
import { Flex } from "@chakra-ui/react";
import CustomText from "../text/CustomText";

function LogoutModal({ isOpen, onClose }: any) {
  const navigate = useNavigate();
  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={() => {
        onClose();
        navigate("/auth/login");
      }}
      headerText={"Logout"}
      btnText={"Confirm, Logout"}
      //   closeBtntext={"Cancel"}
      noCloseFooter={true}
      width={"600px"}
      bgtopBlur="extsy.primary"
    >
      <Flex p="32px" flexDir="column" align="center">
        <CustomText text="Are you sure you want to end this session?" />
      </Flex>
    </CustomModal>
  );
}

export default LogoutModal;
