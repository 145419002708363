import { Box, Input } from "@chakra-ui/react";
import React from "react";

const ColorInput = ({ type, placeholder, value, onChange }: any) => {
  return (
    <Box pos="relative" w={"100%"}>
      <Box
        pos="absolute"
        left="15.5px"
        top="0"
        mt="14px"
        w={"20px"}
        h={"20px"}
        borderRadius={"4px"}
        border={"1px solid "}
        borderColor={"#E9E7EE"}
        bg={value}
        zIndex={2}
      ></Box>
      <Input
        type={type}
        placeholder={placeholder}
        value={value}
        h={"48px"}
        onChange={onChange}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"flex-start"}
        gap={"8px"}
        w={"100%"}
        // w={w ? w : { base: "100%", sm: "270px", md: "373px" }}
        border={"1px solid"}
        borderColor="extsy.neutral300"
        borderRadius="6px"
        color="#2B2B3B6"
        fontSize="16px"
        fontFamily="Roboto"
        bg={"extsy.baseWhite"}
        // pr={rightIco ? "30px" : "auto"}
        p={"14px 17px 13px 44px"}
        _placeholder={{
          color: "extsy.neutral700",
          fontSize: "14px",
          fontWeight: "400",
          fontFamily: "Metropolis",
        }}
        _hover={{
          borderBottom: "none",
          borderColor: "extsy.secondary",
        }}
        _focusVisible={{
          border: "none",
          borderBotom: "none",
        }}
      />
    </Box>
  );
};

export default ColorInput;
