import React, { useState } from "react";
import CustomText from "../../components/text/CustomText";
import {
  Box,
  Center,
  Flex,
  Image,
  ListItem,
  Stack,
  Tooltip,
  UnorderedList,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import AuthCustomInput from "../../components/form/AuthCustomInput";
import { SignUpPayload } from "../../utils/types";
import InfoIcon from "../../assets/images/info-ico.svg";
import EyeIcon from "../../assets/images/eye.svg";
import TickChecked from "../../assets/images/tick-square.svg";
import TickUnchecked from "../../assets/images/tick-square-unchecked.svg";
import PrimaryButton from "../../components/button/primaryButton";
import ErrorIcon from "../../assets/images/error-icon.svg";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [payload, setPayload] = useState<SignUpPayload>({
    email: "",
    password: "",
    confirmPassword: "",
    agreement: true,
    subscribe: false,
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    agreement: "",
  });

  const validateForm = () => {
    let isValid = true;
    let emailError = "";
    let passwordError = "";
    let confirmPasswordError = "";
    let agreementError = "";

    if (payload.email.trim() === "") {
      emailError = "Email is required.";
      isValid = false;
    }

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%?!.&*])[A-Za-z\d@#$%?!.&*]{8,}$/;
    if (!passwordRegex.test(payload.password)) {
      passwordError =
        "Password must contain at least 8 characters, include at least one number, one uppercase letter, and one special character (@#$%?!.&*).";
      isValid = false;
    }

    if (payload.password !== payload.confirmPassword) {
      confirmPasswordError = "Passwords do not match.";
      isValid = false;
    }

    if (!payload.agreement) {
      agreementError = "You must agree to the terms and conditions.";
      isValid = false;
    }

    setErrors({
      email: emailError,
      password: passwordError,
      confirmPassword: confirmPasswordError,
      agreement: agreementError,
    });

    return isValid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      navigate("/auth/verification-code");
      console.log("Form is valid:", payload);
      // Proceed with form submission logic here
    }
  };

  return (
    <Center
      p={"56px 40px"}
      flexDirection={"column"}
      borderRadius={"24px"}
      bg={"rgba(255,255,255,0.04)"}
      backdropFilter={"blur(28px)"}
      maxW={{ base: "358px", md: "480px" }}
      mb={"60px"}
    >
      <CustomText
        text={t("Become our partner")}
        color="extsy.baseWhite"
        family="Power Grotesk"
        size="24px"
      />
      <Box my={"32px"}>
        <CustomText
          text={t(
            "Sign up for a partner account and elevate your platform with our powerful solutions."
          )}
          lh="120%"
          align="center"
          size={{ base: "14px", md: "16px" }}
        />
      </Box>
      <Stack gap={"8px"} w={"100%"} align={"flex-start"}>
        <CustomText
          text="Email"
          color="extsy.neutral500"
          size="12px"
          lh="120%"
        />
        <AuthCustomInput
          placeholder={t("Enter your email")}
          type="email"
          color={
            payload.email === "jhond@gmail.com" || errors?.email
              ? "#ED3D4E"
              : "extsy.text"
          }
          value={payload.email}
          onChange={(e: any) =>
            setPayload({ ...payload, email: e.target.value })
          }
        />
      </Stack>
      {payload.email === "jhond@gmail.com" ||
        (errors?.email && (
          <Flex align={"center"} w={"100%"} mt={"22px"} gap={"8px"}>
            <Image src={ErrorIcon} alt="error icon" />
            <CustomText
              text={
                errors?.email
                  ? errors?.email
                  : "This email is already registered. Sign in or try with another email ID"
              }
              color="#ED3D4E"
              size={{ base: "10px", md: "12px" }}
            />
          </Flex>
        ))}
      <Stack gap={"8px"} w={"100%"} my={"22px"} align={"flex-start"}>
        <Flex align={"center"} gap={"8px"}>
          <CustomText
            text="Password"
            color="extsy.neutral500"
            size="12px"
            lh="120%"
          />
          <Tooltip
            bg={"transparent"}
            label={
              <Flex
                direction="column"
                justify="center"
                align="center"
                gap="10px"
                p="16px"
                w={{ base: "315px", md: "274px" }}
                borderRadius="23px"
                border="1px solid"
                borderColor="var(--Neutrals-Neutrals800, #4E4949)"
                bg="rgba(255, 255, 255, 0.03)"
                backdropFilter="blur(12px)"
                color="var(--Neutrals-Neutrals300, #BAB8B8)"
                fontSize="16px"
                lineHeight="120%"
                fontFamily="Metropolis"
                ml={{ base: "-85px", md: "0" }}
                mt={{ base: "130px", md: "0" }}
              >
                <UnorderedList>
                  <ListItem>
                    <CustomText
                      text="The password must contain at least 8 characters."
                      color="extsy.neutral300"
                      lh="120%"
                    />
                  </ListItem>
                  <ListItem>
                    <CustomText
                      text="Include at least one number."
                      color="extsy.neutral300"
                      lh="120%"
                    />
                  </ListItem>
                  <ListItem>
                    <CustomText
                      text="Include at least one uppercase letter."
                      color="extsy.neutral300"
                      lh="120%"
                    />
                  </ListItem>
                  <ListItem>
                    <CustomText
                      text=" Use one of the following supported special characters:
                  @#$%?!.&*"
                      color="extsy.neutral300"
                      lh="120%"
                    />
                  </ListItem>
                </UnorderedList>
              </Flex>
            }
            placement={"auto-start"}
            shouldWrapChildren
          >
            <Image src={InfoIcon} alt="info" w="16px" />
          </Tooltip>
        </Flex>
        <AuthCustomInput
          placeholder={t("Enter your password")}
          type={isPasswordVisible ? "text" : "password"}
          value={payload.password}
          rightIco={EyeIcon}
          onIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
          onChange={(e: any) =>
            setPayload({ ...payload, password: e.target.value })
          }
        />
      </Stack>
      {errors?.password && (
        <Flex align={"center"} w={"100%"} mb={"22px"} gap={"8px"}>
          <Image src={ErrorIcon} alt="error icon" />
          <CustomText
            text={errors?.password}
            color="#ED3D4E"
            size={{ base: "10px", md: "12px" }}
          />
        </Flex>
      )}
      <Stack gap={"8px"} w={"100%"} align={"flex-start"}>
        <CustomText
          text="Confirm Password"
          color="extsy.neutral500"
          size="12px"
          lh="120%"
        />

        <AuthCustomInput
          placeholder={t("Re-enter your password")}
          type={isConfirmPasswordVisible ? "text" : "password"}
          value={payload.confirmPassword}
          rightIco={EyeIcon}
          onIconClick={() =>
            setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
          }
          onChange={(e: any) =>
            setPayload({ ...payload, confirmPassword: e.target.value })
          }
        />
      </Stack>
      {errors?.confirmPassword && (
        <Flex align={"center"} w={"100%"} mt={"22px"} gap={"8px"}>
          <Image src={ErrorIcon} alt="error icon" />
          <CustomText
            text={errors?.confirmPassword}
            color="#ED3D4E"
            size={{ base: "10px", md: "12px" }}
          />
        </Flex>
      )}
      <Flex align={"center"} gap={"8px"} w={"100%"} my={"22px"}>
        <Image
          src={payload?.agreement ? TickChecked : TickUnchecked}
          alt="checkbox"
          onClick={() =>
            setPayload({ ...payload, agreement: !payload.agreement })
          }
          cursor={"pointer"}
        />
        <Flex align={"center"} gap={"2px"} flexWrap={"wrap"}>
          <CustomText
            text="I've read and agree to the Extsy "
            family="Titillium Web"
            size="14px"
          />
          <CustomText
            text=" Terms of Use,"
            family="Titillium Web"
            size="14px"
            underline={true}
          />
          <CustomText
            text="Privacy Policy"
            family="Titillium Web"
            size="14px"
            underline={true}
          />
        </Flex>
      </Flex>
      {errors?.agreement && (
        <Flex align={"center"} w={"100%"} mb={"22px"} gap={"8px"}>
          <Image src={ErrorIcon} alt="error icon" />
          <CustomText
            text={errors?.agreement}
            color="#ED3D4E"
            size={{ base: "10px", md: "12px" }}
          />
        </Flex>
      )}
      <Flex align={"center"} gap={"8px"} w={"100%"} mb={"25px"}>
        <Image
          src={payload?.subscribe ? TickChecked : TickUnchecked}
          alt="checkbox"
          cursor={"pointer"}
          onClick={() =>
            setPayload({ ...payload, subscribe: !payload.subscribe })
          }
        />
        <CustomText
          text="Subscribe to the newsletter"
          family="Titillium Web"
          size="14px"
        />
      </Flex>
      <Box mt={{ base: "90px", md: "0px" }} w={"100%"}>
        <PrimaryButton title="Sign Up" onClick={handleSubmit} />
      </Box>
      <Center mt={"32px"} gap={"2px"}>
        <CustomText
          text="Already have an account?"
          color="extsy.neutral300"
          lh="120%"
        />
        <CustomText
          text="Sign In"
          color="extsy.neutral300"
          lh="120%"
          weight="600"
          bgGradient="linear(to-r, #FEF9CC, #F6DBD4 40%, #F1C8D9 75%, #F0C2DB)"
          bgClip="text"
          cursor="pointer"
          onClick={() => {
            navigate("/auth/login");
          }}
        />
      </Center>
    </Center>
  );
};

export default Signup;
