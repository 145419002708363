import { Box, Center, Flex, Image } from "@chakra-ui/react";
import React from "react";
import CustomText from "../../components/text/CustomText";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SecurityIcon from "../../assets/images/security-icon.svg";
import CustomVerificationInput from "../../components/form/CustomVerificationInput";
import PrimaryButton from "../../components/button/primaryButton";

const LoginSecurityCode = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Center
      p={"56px 40px"}
      flexDirection={"column"}
      borderRadius={"24px"}
      bg={"rgba(255,255,255,0.04)"}
      backdropFilter={"blur(28px)"}
      maxW={{ base: "358px", md: "480px" }}
      mb={"60px"}
    >
      <CustomText
        text={t("Enter Security Code")}
        color="extsy.baseWhite"
        family="Power Grotesk"
        size="24px"
      />
      <Box my={"32px"}>
        <CustomText
          text={t(
            "To continue to Extsy, please enter security code provided by your authenticator."
          )}
          lh="120%"
          align="center"
          size={{ base: "14px", md: "16px" }}
        />
      </Box>
      <Box mt={"32px"} mb={"48px"}>
        <Image src={SecurityIcon} alt="security" />
      </Box>
      <Flex
        flexDir="column"
        align="center"
        justify="center"
        gap="40px"
        w={"100%"}
      >
        <CustomVerificationInput placeholder={0} />
      </Flex>
      <Box mt={{ base: "90px", md: "40px" }} w={"100%"}>
        <PrimaryButton
          title="Verify Code"
          onClick={() => navigate("/auth/api-key")}
        />
      </Box>
    </Center>
  );
};

export default LoginSecurityCode;
