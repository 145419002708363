import {
  Box,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import CrossIcon from "../../assets/images/cross.svg";

import { useTranslation } from "react-i18next";
import PrimaryButton from "../button/primaryButton";
import CloseButton from "../button/closeButton";

interface CustomModalProps {
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  headerText?: string;

  onSubmit: () => void;
  children: any;
  footerText?: string;
  closeText?: string;
  btnDirection?: boolean;
  btnBgColor?: string;
  btnColor?: string;
  btnBorderColor?: string;
  isDisabled?: boolean;
  noFooter?: boolean;
  noHeader?: boolean;
  noCloseFooter?: boolean;
  height?: string;
  padding?: string;
  btnText?: string;
  closeBtntext?: string;
  width?: string;
  btnContinueWidth?: string;
  btnCloseWidth?: string;
  bgtopBlur?: string;
  bg?: string;
  borderColor?: string;
  bgFilter?: string;
}

const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onClose,
  noHeader,
  headerText,
  isLoading,
  onSubmit,
  children,
  footerText,
  closeText,
  btnDirection,
  btnBgColor,
  btnColor,
  btnBorderColor,
  isDisabled,
  noFooter,
  noCloseFooter,
  height,
  padding,
  btnText,
  closeBtntext,
  width,
  btnContinueWidth,
  btnCloseWidth,
  bgtopBlur,
  bg,
  borderColor,
  bgFilter,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      // size={["xs", "sm", "md", "lg"]}
      isCentered
    >
      <ModalOverlay backdropFilter={"blur(0.9px)"} />
      <ModalContent
        bg={bg || "rgba(255, 255, 255, 0.0008)"}
        color="extsy.baseWhite"
        border="1px solid"
        borderColor={borderColor || "extsy.neutral800"}
        padding={padding ? padding : ""}
        // h={height?height:"420px"}
        minW={width || { base: "300px", sm: "358px", md: "600px" }}
        borderRadius="16px"
        pos="relative"
        overflow="hidden"
        backdropFilter={bgFilter || "blur(17px)"}
      >
        <Box
          width="696px"
          height="245px"
          borderRadius="696px"
          opacity="0.6"
          bg={bgtopBlur || "extsy.primary"}
          filter="blur(97px)"
          pos="absolute"
          left="-302px"
          top="-164px"
          transform="rotate(-30deg)"
          zIndex="-1"
        />
        {noHeader ? (
          <></>
        ) : (
          <ModalHeader
            padding={"16px 24px"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            borderBottom={`1px solid `}
            borderColor={"extsy.neutral900"}
          >
            <Text
              fontSize={["11px", "13px", "15px", "18px"]}
              fontWeight={"700"}
              lineHeight={"22px"}
              letterSpacing={"0.08px"}
              fontFamily={"Metropolis"}
            >
              {headerText}
            </Text>
            <Image
              src={CrossIcon}
              cursor={"pointer"}
              onClick={onClose}
              w={"18px"}
            />
          </ModalHeader>
        )}
        <ModalBody padding="0px">{children}</ModalBody>
        {noFooter ? (
          <></>
        ) : (
          <ModalFooter
            display={"flex"}
            padding={"0px"}
            borderTop={`1px solid `}
            borderColor={"extsy.neutral900"}
          >
            <Flex
              padding={"16px 24px "}
              width={"100%"}
              gap={"16px"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={{ base: "column-reverse", md: "row" }}
            >
              {!noCloseFooter && (
                <CloseButton
                  title={closeBtntext || t("remindMeLater")}
                  maxW={btnCloseWidth || "100%"}
                  onClick={onClose}
                />
              )}
              <PrimaryButton
                title={btnText || t("confirmNow")}
                padding="10px 32px "
                maxW={btnContinueWidth || "100%"}
                onClick={onSubmit}
              />
            </Flex>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;
