import React from "react";
import { Flex, Box, Image } from "@chakra-ui/react";
import CustomText from "../text/CustomText";
import Step from "../../assets/images/step.svg";
import Loader from "../../assets/images/loader.svg";
import HorizontalLine from "../../assets/images/widget-hor-line.svg";

const StepIndicator = () => {
  // Helper to render a single step
  const renderStep = (stepNum: any, icon: any, text: any) => (
    <Flex
      direction="column"
      align="center"
      justify={"flex-start"}
      h={"80px"}
      gap={"8px"}
      w={"150px"}
    >
      <Image src={icon} alt="img" mt={stepNum === 1 ? "10px" : "0px"} />
      <Box maxW={"96px"}>
        <CustomText
          text={text}
          color="extsy.baseWhite"
          family="Titillium Web"
          size="18px"
          lh="20px"
        />
      </Box>
    </Flex>
  );

  return (
    <Flex align="center" bg={"transparent"}>
      {renderStep(1, Loader, "Waiting For Deposit")}
      <Box mt={"-37px"} mx={"-40px"}>
        <Image src={HorizontalLine} w={"180px"} />
      </Box>

      {renderStep(2, Step, "Waiting For Confirmation")}
      <Box mt={"-37px"} mx={"-40px"}>
        <Image src={HorizontalLine} w={"180px"} />
      </Box>
      {renderStep(3, Step, "Swapping")}
      <Box mt={"-37px"} mx={"-40px"}>
        <Image src={HorizontalLine} w={"180px"} />
      </Box>
      {renderStep(4, Step, "Sending To You")}
    </Flex>
  );
};

export default StepIndicator;
