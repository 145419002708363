import { Grid, GridItem } from "@chakra-ui/react";
import React from "react";
import WidgetType from "../../components/card/WidgetType";
import SwapNow from "../../components/card/SwapNow";
import SourceCode from "../../components/card/SourceCode";

const Widget = () => {
  return (
    <Grid
      gap="24px"
      templateColumns={{
        base: "1fr",
        lg: "1fr 1fr", // 2/3 of the space for the left column, 1/3 for the right
      }}
      templateRows={{
        lg: "1fr 1fr", // 2 rows on the right side
      }}
    >
      {/* WidgetType - takes all space in the left column */}
      <GridItem
        gridColumn={{
          base: "span 12",
          lg: "span 1", // Span the left column
        }}
        gridRow={{
          base: "auto",
          lg: "span 2", // Takes both rows vertically
        }}
        order={{
          base: 3,
          lg: 1,
        }}
      >
        <WidgetType />
      </GridItem>

      {/* SwapNow - takes the top half of the right column */}
      <GridItem
        gridColumn={{
          base: "span 12",
          lg: "span 1", // Span the right column
        }}
        gridRow={{
          base: "auto",
          lg: "span 1", // Top row
        }}
        order={{
          base: 1,
          lg: 2,
        }}
      >
        <SwapNow />
      </GridItem>

      {/* SourceCode - takes the bottom half of the right column */}
      <GridItem
        gridColumn={{
          base: "span 12",
          lg: "span 1", // Span the right column
        }}
        gridRow={{
          base: "auto",
          lg: "span 1", // Bottom row
        }}
        order={{
          base: 2,
          lg: 3,
        }}
      >
        <SourceCode />
      </GridItem>
    </Grid>
  );
};

export default Widget;
