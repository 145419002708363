import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import AddressTable from "../../components/settings/AddressTable";
import LinkTable from "../../components/settings/LinkTable";
import AccountDetail from "../../components/card/AccountDetail";
const Settings = () => {
  return (
    <Grid
      gap={{ base: "16px", md: "24px" }}
      templateColumns={{
        base: "1fr",
        sm: "repeat(12, 1fr)",
      }}
    >
      <GridItem
        gridColumn={{
          base: "span 12",
          md: "span 12",
          lg: "span 7",
        }}
        order={{
          base: 2, // Show second on base and md
          lg: 1, // Show first on large screens
        }}
      >
        <AddressTable />
      </GridItem>

      <GridItem
        order={{
          base: 1, // Show first on base and md
          lg: 2, // Show second on large screens
        }}
        gridColumn={{
          base: "span 12",
          md: "span 12",
          lg: "span 5",
        }}
      >
        <AccountDetail />
      </GridItem>

      <GridItem
        order={{
          base: 3, // Show first on base and md
          lg: 3, // Show second on large screens
        }}
        gridColumn={{
          base: "span 12",
          md: "span 12",
          lg: "span 7",
        }}
      >
        <LinkTable />
      </GridItem>
    </Grid>
  );
};

export default Settings;
