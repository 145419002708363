import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/sidebar";
import Navbar from "../components/Header/Navbar";

function DashboardLayout() {
  const [isLessThan1600] = useMediaQuery("(max-width: 1600px)");

  return (
    <Flex
      p={{ base: "16px", lg: "24px" }}
      gap={isLessThan1600 ? "32px" : "48px"}
      alignItems="flex-start"
      bg="extsy.bg"
      minH="100vh"
      maxH="100vh"
      overflow="hidden"
    >
      <Box display={{ base: "none", md: "block" }}>
        <Sidebar />
      </Box>
      <Flex gap="24px" flexDir="column" width="100%">
        <Navbar />
        <Box
          overflowY="auto"
          maxH="calc(100vh - 120px)"
          pb={{ base: "24px", lg: "35px" }}
        >
          <Outlet />
        </Box>
      </Flex>
    </Flex>
  );
}

export default DashboardLayout;
