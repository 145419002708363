import {
  Box,
  Center,
  Flex,
  Image,
  Input,
  Stack,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../text/CustomText";
import { useTranslation } from "react-i18next";
import AmountRaising from "../../assets/images/amount-rising.svg";
import EthIco from "../../assets/images/eth-ico.svg";
import BtcIco from "../../assets/images/btc-ico.svg";
import { IconChevronDown, IconCopy, IconQrcode } from "@tabler/icons-react";
import UnlockIco from "../../assets/images/unlock-ico.svg";
import InfoIco from "../../assets/images/info-ico.svg";
import SwapIcon from "../../assets/images/swap-icon.svg";
import LockIcon from "../../assets/images/lock-green.svg";
import CopyClipboard from "../../assets/images/copy-clipboard.svg";
import AgreeCheckbox from "../../assets/images/agree-checkbox.svg";
import CryptoCurrencyDropdown from "../form/CryptoCurrencyDropdown";
import SwapAmountInput from "../form/SwapAmountInput";
import PrimaryButton from "../button/primaryButton";
import Logo from "../../assets/images/logo-icon.svg";
import LogoText from "../../assets/images/extsy-logo.svg";

import CustomModal from "../modal/customModal";
import WidgetBg from "../../assets/images/widget-bg.svg";
import StepIndicator from "../dropdown/StepIndicator";

function SwapNow({ swap }: any) {
  const { t } = useTranslation();

  const [isLessThan1242] = useMediaQuery("(max-width: 1242px)");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const [isFromSwap, setIsFromSwap] = useState(swap || false);
  const [isPayingCrypto, setIsPayingCrypto] = useState(true);
  const [isFixedPrice, setIsFixedPrice] = useState(false);
  const [activeCurrency, setActiveCurrency] = useState<any>({
    currency1: {
      symbol: "BTC",
      name: "Bitcoin",
      icon: BtcIco,
      price: "$56,623.54",
    },
    currency2: {
      symbol: "ETH",
      name: "Ethereum",
      icon: EthIco,
      price: "$3,456.54",
    },
  });
  const [isCurrency1Open, setIsCurrency1Open] = useState(false);
  const [isCurrency2Open, setIsCurrency2Open] = useState(false);

  useEffect(() => {
    if (isLessThan1242) {
      setIsFromSwap(false);
    } else {
      setIsFromSwap(swap);
    }
  }, [isLessThan1242, swap]);

  return (
    <Box
      position="relative"
      p={{ base: "24px 10px", md: "24px" }}
      bg="extsy.neutral1000"
      borderRadius="24px"
      boxShadow="0px 3px 4px 0px rgba(0, 0, 0, 0.03)"
    >
      <Flex
        align={"center"}
        justify={"space-between"}
        ml={{ md: "20px" }}
        mb={"23px"}
      >
        <Flex gap="6px" cursor="pointer">
          <Image src={Logo} alt="logo" width={"36px"} height={"26px"} />

          <Image src={LogoText} alt="logo" width="71px" height="25px" />
        </Flex>
        <Flex
          align={"center"}
          gap={"12px"}
          flexDirection={{ base: "column", md: "row" }}
        >
          <CustomText
            text="Check Tx Status"
            color="extsy.baseWhite"
            underline={{ base: "underline", md: "none" }}
          />
          <Box display={{ base: "none", md: "block" }}>
            <CustomText text="|" color="extsy.baseWhite" />
          </Box>
          <CustomText text="English" color="extsy.baseWhite" />
        </Flex>
      </Flex>
      <Flex flexDir={isFromSwap ? "row" : "column"} gap="10px">
        {/* Amount swapping */}
        <Box
          p={{ base: "16px", lg: "30px 27px 35px 27px" }}
          borderRadius={{ base: "16px", lg: "20.5px" }}
          border="0.857px solid"
          borderColor="extsy.white"
          backdropBlur="blur(5.39px)"
          pos="relative"
          width={isFromSwap ? "100%" : "auto"}
        >
          <Flex align="center" justify="space-between" gap="16px">
            <Flex flexDir="column" gap="9px" maxW="164px">
              <SwapAmountInput fontSize={{ base: "14px", lg: "27px" }} />
              <Flex align="center" gap="7px">
                <Image src={AmountRaising} alt="up" />
                <CustomText
                  text="+ 3,390 (5.94%)"
                  size={{ base: "12px", lg: "15.4px" }}
                  family="Titillium Web"
                  color="extsy.white"
                />
              </Flex>
            </Flex>
            <Flex
              align="center"
              justify="end"
              gap="10px"
              minW={{ base: "140px", lg: "160px" }}
            >
              {isPayingCrypto ? (
                <PayingCrypto
                  onClick={() => {
                    setIsCurrency1Open(!isCurrency1Open);
                  }}
                  activeCurrency={activeCurrency?.currency1}
                />
              ) : (
                <ReceivingCrypto
                  onClick={() => {
                    setIsCurrency2Open(!isCurrency2Open);
                  }}
                  activeCurrency={activeCurrency?.currency2}
                />
              )}
            </Flex>
          </Flex>
          {isCurrency1Open && (
            <CryptoCurrencyDropdown
              activeCurrency={activeCurrency.currency1}
              onClick={(currency: any) => {
                setActiveCurrency({
                  ...activeCurrency,
                  currency1: currency,
                });
                setIsCurrency1Open(false);
                setIsCurrency2Open(false);
              }}
            />
          )}
        </Box>
        <Image
          src={SwapIcon}
          alt="swap"
          cursor="pointer"
          mx="auto"
          mt={{ base: "-30px", lg: isFromSwap ? "-30px" : "-35px" }}
          zIndex="1000"
          onClick={() => {
            setIsCurrency1Open(false);
            setIsCurrency2Open(false);
            setIsPayingCrypto(!isPayingCrypto);
          }}
        />
        {/* Amount receiving */}
        <Box
          mt={{ base: "-30px", lg: isFromSwap ? "-28px" : "-35px" }}
          p={{ base: "16px", lg: "30px 27px 35px 27px" }}
          borderRadius={{ base: "16px", lg: "20.5px" }}
          border="0.857px solid"
          borderColor="extsy.white"
          backdropBlur="blur(5.39px)"
          pos="relative"
          width={isFromSwap ? "100%" : "auto"}
        >
          <Flex align="center" justify="space-between" gap="16px">
            <Flex flexDir="column" gap="9px" maxW="164px">
              <SwapAmountInput fontSize={{ base: "14px", lg: "27px" }} />
              <Flex align="center" gap="7px">
                <Image src={AmountRaising} alt="up" />
                <CustomText
                  text="+ 3,390 (5.94%)"
                  size={{ base: "12px", lg: "15.4px" }}
                  family="Titillium Web"
                  color="extsy.white"
                />
              </Flex>
            </Flex>
            <Flex
              align="center"
              gap="10px"
              minW={{ base: "140px", lg: "160px" }}
            >
              <Image
                src={isFixedPrice ? LockIcon : UnlockIco}
                alt="unlock"
                pr="8px"
                height="30px"
                width="30px"
                borderRight="1px solid"
                borderColor="extsy.text"
                cursor="pointer"
                onClick={() => setIsFixedPrice(!isFixedPrice)}
              />
              {!isPayingCrypto ? (
                <PayingCrypto
                  onClick={() => {
                    setIsCurrency1Open(!isCurrency1Open);
                  }}
                  activeCurrency={activeCurrency?.currency1}
                />
              ) : (
                <ReceivingCrypto
                  onClick={() => {
                    setIsCurrency2Open(!isCurrency2Open);
                  }}
                  activeCurrency={activeCurrency?.currency2}
                />
              )}{" "}
            </Flex>
          </Flex>
          {isCurrency2Open && (
            <CryptoCurrencyDropdown
              activeCurrency={activeCurrency.currency2}
              onClick={(currency: any) => {
                setActiveCurrency({
                  ...activeCurrency,
                  currency2: currency,
                });
                setIsCurrency1Open(false);
                setIsCurrency2Open(false);
              }}
            />
          )}
        </Box>
      </Flex>
      {swap && (
        <Box pos="relative" mt={isLessThan1242 ? "12px" : "24px"}>
          <Input
            p="24px 48px 24px 24px"
            height="70px"
            type="text"
            borderRadius="12px"
            border="1px solid"
            borderColor="extsy.white"
            placeholder={t("receipientWalletAddress")}
            fontSize="18px"
            fontWeight="400"
            fontFamily="Titillium Web"
            color="extsy.white"
            _hover={{ borderColor: "extsy.white" }}
            _focus={{ borderColor: "extsy.white" }}
            _focusVisible={{ borderColor: "extsy.white" }}
          />
          <Image
            src={CopyClipboard}
            alt="copy"
            pos="absolute"
            right="0"
            top="0"
            mr="24px"
            mt="24px"
          />
        </Box>
      )}
      <Flex
        flexDir={isFromSwap ? { base: "column", md: "row" } : "column"}
        align={isFromSwap ? { base: "left", md: "center" } : "left"}
        mb="20px"
        mt={isFromSwap ? "24px" : "8px"}
        gap="10px"
        px={
          isFromSwap ? { base: "43px", md: "0px" } : { base: "0px", md: "43px" }
        }
      >
        <Flex align="center" gap="10px" justify="space-between">
          <Flex align="center" gap="6px">
            <CustomText
              text={t("Compare Prices")}
              size="10px"
              family="Titillium Web"
              color="extsy.white"
              underline
              cursor="pointer"
            />
            <Image src={InfoIco} alt="info" />
          </Flex>
          <Flex align="center" gap="6px">
            <CustomText
              text={t("Signup to get cashback")}
              size="10px"
              family="Titillium Web"
              color="extsy.white"
              underline
              cursor="pointer"
            />
            <Image src={InfoIco} alt="info" />
          </Flex>
        </Flex>
      </Flex>
      {swap && (
        <Flex my={isLessThan1242 ? "12px" : "17px"} gap="8px" align="center">
          <Image src={AgreeCheckbox} alt="agree" />
          <CustomText
            text={t("readAgreeTerms")}
            family="Titillium Web"
            size="12px"
            lh="16px"
            color="extsy.white"
          />
        </Flex>
      )}
      <PrimaryButton
        title={t("Swap Now")}
        width="100%"
        height="47px"
        onClick={onOpen}
      />
      <CustomModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          onDeleteOpen();
        }}
        onSubmit={() => {
          onClose();
          onDeleteOpen();
        }}
        headerText={"Please send the funds you would like to exchange"}
        noFooter={true}
        width={"800px"}
        bgtopBlur="extsy.primary"
      >
        <Stack
          gap={"24px"}
          pb={"33px"}
          borderRadius={"23px"}
          w={"95%"}
          mx={"auto"}
        >
          <Stack
            bgImage={WidgetBg}
            bgPos={"center"}
            bgSize={"cover"}
            mt={"24px"}
            borderRadius={"24px"}
            p={"44px"}
            gap={"24px"}
          >
            <Flex align={"center"}>
              <Stack w={"60%"} gap={"34px"}>
                <Flex align={"center"} justify={"space-between"}>
                  <Flex align={"center"} gap={"8px"}>
                    <Image src={BtcIco} alt="btc" />
                    <Stack gap={"8px"}>
                      <CustomText
                        text="Amount"
                        color="#626262"
                        family="Titillium Web"
                        size="24px"
                      />
                      <CustomText
                        text="0.5 BTC"
                        color="extsy.baseWhite"
                        family="Titillium Web"
                        size="24px"
                        weight="700"
                      />
                    </Stack>
                  </Flex>
                  <Flex align={"center"} gap={"8px"}>
                    <Image src={EthIco} alt="eth" />
                    <Stack gap={"8px"}>
                      <CustomText
                        text="You Get"
                        color="#626262"
                        family="Titillium Web"
                        size="24px"
                      />
                      <CustomText
                        text="9.969218 USDC"
                        color="extsy.baseWhite"
                        family="Titillium Web"
                        size="24px"
                        weight="700"
                      />
                    </Stack>
                  </Flex>
                </Flex>
                <Stack gap={"8px"}>
                  <CustomText
                    text="To this wallet"
                    color="#626262"
                    family="Titillium Web"
                    size="24px"
                  />
                  <CustomText
                    text="1dW138SUhk3L0ovKdef5ypcHNcxKtAwpT8"
                    color="extsy.baseWhite"
                    family="Titillium Web"
                    size="24px"
                    weight="700"
                  />
                </Stack>
                <Flex align={"center"} justify={"space-between"}>
                  <Stack gap={"8px"}>
                    <CustomText
                      text="Time left for the transaction"
                      color="#626262"
                      family="Titillium Web"
                      size="24px"
                    />
                    <CustomText
                      text="20:00"
                      color="extsy.baseWhite"
                      family="Titillium Web"
                      size="24px"
                      weight="700"
                    />
                  </Stack>
                  <Stack gap={"8px"}>
                    <CustomText
                      text="Estimated Rate"
                      color="#626262"
                      family="Titillium Web"
                      size="24px"
                    />
                    <CustomText
                      text="1 BTC ~ 22.22413 ETH"
                      color="extsy.baseWhite"
                      family="Titillium Web"
                      size="24px"
                      weight="700"
                    />
                  </Stack>
                </Flex>
              </Stack>
              <IconQrcode width={"40%"} size={"xl"} />
            </Flex>
            <Center>
              <StepIndicator />
            </Center>
          </Stack>
          <Flex align={"center"} justify={"space-between"}>
            <CustomText text="You will get result to wallet 0x000000000000" />
            <Flex align={"center"} gap={"4px"}>
              <CustomText text="Order ID" />
              <PrimaryButton
                title="bcb87weyhrnwes"
                icon2={<IconCopy />}
                height="34px"
              />
            </Flex>
          </Flex>
        </Stack>
      </CustomModal>
      <CustomModal
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        onSubmit={() => {
          onDeleteClose();
        }}
        noHeader={true}
        noFooter={true}
        // width={"800px"}
        // bgtopBlur="extsy.primary"
      >
        <Center
          flexDirection={"column"}
          gap={"32px"}
          p={"24px"}
          borderRadius={"23px"}
          border={"1px solid"}
          borderColor="extsy.neutral800"
          bg={"rgba(255,255,255,0.10)"}
        >
          <CustomText
            text="Confirmation"
            color="extsy.baseWhite"
            family="Power Grotesk"
            size="24px"
          />
          <CustomText text="Are you sure you want to close" />
          <Flex align={"center"} gap={"16px"} w={"100%"}>
            <PrimaryButton
              title="Close"
              w="100%"
              bg="rgba(255,255,255,0.10)"
              color="extsy.baseWhite"
              onClick={onDeleteClose}
            />
            <PrimaryButton title="Confirm" w="100%" onClick={onDeleteClose} />
          </Flex>
        </Center>
      </CustomModal>
    </Box>
  );
}

const PayingCrypto = ({ onClick, activeCurrency }: any) => {
  return (
    <Flex gap="10px" align="center" onClick={onClick}>
      <Flex flexDir="column" gap="2px" align="flex-end" cursor="pointer">
        <Flex align="center" gap="8px">
          <IconChevronDown color="#fff" size="16px" />
          <CustomText
            text={activeCurrency.symbol}
            size={{ base: "14px", lg: "20px" }}
            weight="700"
            family="Titillium Web"
            color="extsy.white"
            cursor="pointer"
          />
        </Flex>
        <CustomText
          text={activeCurrency.name}
          size={{ base: "12px", lg: "15px" }}
          color="extsy.white"
          family="Titillium Web"
          cursor="pointer"
        />
      </Flex>
      <Image
        src={activeCurrency?.icon}
        alt="btc"
        cursor="pointer"
        height={{ base: "40px", lg: "48px" }}
        width={{ base: "40px", lg: "48px" }}
      />
    </Flex>
  );
};

const ReceivingCrypto = ({ onClick, activeCurrency }: any) => {
  return (
    <Flex align="center" gap="10px" cursor="pointer" onClick={onClick}>
      <Flex flexDir="column" gap="2px" align="flex-end">
        <Flex align="center" gap="8px">
          <IconChevronDown color="#fff" size="16px" />
          <CustomText
            text={activeCurrency.symbol}
            size={{ base: "14px", lg: "20px" }}
            weight="700"
            family="Titillium Web"
            color="extsy.white"
            cursor="pointer"
          />
        </Flex>
        <CustomText
          text={activeCurrency.name}
          size={{ base: "12px", lg: "15px" }}
          color="extsy.white"
          family="Titillium Web"
          cursor="pointer"
        />
      </Flex>
      <Image
        src={activeCurrency?.icon}
        alt="eth"
        cursor="pointer"
        height={{ base: "40px", lg: "48px" }}
        width={{ base: "40px", lg: "48px" }}
      />
    </Flex>
  );
};

export default SwapNow;
