import {
  Flex,
  Image,
  Stack,
  Tooltip,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import LogoText from "../../assets/images/extsy-logo.svg";
import Logo from "../../assets/images/logo-icon.svg";
import ArrowCollapseIco from "../../assets/images/collapse-ico.svg";
import CollapseOpenIco from "../../assets/images/open-collapse-ico.svg";

import CustomText from "../text/CustomText";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomModal from "../modal/customModal";
import { DASHBOARD_MENU, DASHBOARD_OTHER_MENU } from "../../utils/constants";

function Sidebar() {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const {
    isOpen: isLogoutOpen,
    onOpen: onLogoutOpen,
    onClose: onLogoutClose,
  } = useDisclosure();
  const [isLessThan1600] = useMediaQuery("(max-width: 1600px)");
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleNavigate = (path: any) => {
    navigate(path);
  };

  useEffect(() => {
    if (isLessThan1600) {
      setIsCollapsed(true);
    }
  }, [isLessThan1600]);

  return (
    <Flex
      p="24px"
      bg="extsy.neutral1000"
      w={isCollapsed ? "92px" : "280px"}
      borderRadius="24px"
      h="calc(100vh - 48px)"
      alignItems="flex-start"
      transition="width 0.3s"
      flexDir="column"
    >
      <Flex justify="space-between" align="center" w="100%">
        <Flex gap="6px" cursor="pointer">
          <Image src={Logo} alt="logo" width={"36px"} height={"26px"} />
          {!isCollapsed && (
            <Image src={LogoText} alt="logo" width="71px" height="25px" />
          )}
        </Flex>
        <Flex
          display={isLessThan1600 ? "none" : "flex"}
          cursor="pointer"
          height="30px"
          width="30px"
          bg="extsy.text"
          borderRadius="8px"
          align="center"
          justify="center"
          marginRight="-38px"
          userSelect="none"
          onClick={() => {
            setIsCollapsed(!isCollapsed);
          }}
        >
          <Image
            src={isCollapsed ? CollapseOpenIco : ArrowCollapseIco}
            alt="collapse"
          />
        </Flex>
      </Flex>
      <Stack
        mt="40px"
        justifyContent="space-between"
        align={"flex-start"}
        alignItems={"flex-start"}
        overflowY="auto"
        h="100%"
        __css={{
          "&::-webkit-scrollbar": {
            width: "0px",
          },
          "&::-webkit-scrollbar-thumb": {
            bg: "extsy.neutral900",
            borderRadius: "8px",
          },
        }}
      >
        <Stack align={"flex-start"}>
          <CustomText
            text={t("menu")}
            color="extsy.neutral200"
            size="12px"
            weight="600"
            ls="0.12px"
            align={isCollapsed ? "center" : "start"}
          />
          <Flex flexDir="column" gap="8px" mt="8px" width="100%">
            {DASHBOARD_MENU.map((item, index) => (
              <Tooltip
                label={item?.name}
                display={isCollapsed ? "flex" : "none"}
                placement="right"
                ml="10px"
                alignItems="center"
                justifyContent="center"
                borderRadius="8px"
                bg="extsy.g1"
                p="8px 24px"
                color="extsy.bg"
                fontFamily="Metropolis"
                fontSize="16px"
                fontWeight="400"
              >
                <Flex
                  key={index}
                  align="center"
                  justify={isCollapsed ? "center" : "flex-start"}
                  cursor="pointer"
                  p={isCollapsed ? "0px" : "12px"}
                  height="44px"
                  width={isCollapsed ? "44px" : "232px"}
                  gap="8px"
                  borderRadius="8px"
                  bg={item.path === pathname ? "extsy.g2" : "transparent"}
                  onClick={() => handleNavigate(item?.path)}
                >
                  <Image
                    src={
                      pathname === item?.path
                        ? item.activeIco
                        : item.inActiveIco
                    }
                    alt={item?.name}
                    width="24px"
                    height="24px"
                  />
                  {!isCollapsed && (
                    <CustomText
                      text={item.name}
                      color={
                        item?.path === pathname
                          ? "extsy.bg"
                          : "extsy.neutral300"
                      }
                      cursor="pointer"
                    />
                  )}
                </Flex>
              </Tooltip>
            ))}
          </Flex>
        </Stack>
        <Stack>
          <CustomText
            text={t("others")}
            mt="20px"
            color="extsy.neutral200"
            size="12px"
            weight="600"
            ls="0.12px"
            align={isCollapsed ? "center" : "start"}
          />
          <Flex flexDir="column" gap="8px" mt="8px" width="100%">
            {DASHBOARD_OTHER_MENU.map((item, index) => (
              <Tooltip
                label={item?.name}
                display={isCollapsed ? "flex" : "none"}
                placement="right"
                ml="10px"
                alignItems="center"
                justifyContent="center"
                borderRadius="8px"
                bg="extsy.g1"
                p="8px 24px"
                color="extsy.bg"
                fontFamily="Metropolis"
                fontSize="16px"
                fontWeight="400"
              >
                <Flex
                  key={index}
                  align="center"
                  justify={isCollapsed ? "center" : "flex-start"}
                  cursor="pointer"
                  p={isCollapsed ? "0px" : "12px"}
                  height="44px"
                  width={isCollapsed ? "44px" : "232px"}
                  gap="8px"
                  borderRadius="8px"
                  bg={item.path === pathname ? "extsy.g2" : "transparent"}
                  onClick={() => {
                    if (item?.name === "Logout") {
                      onLogoutOpen();
                    } else if (!item.disabled) {
                      // Only handle navigation if the item is not disabled
                      handleNavigate(item?.path);
                    }
                  }}
                >
                  <Image
                    src={
                      item?.name === "Logout"
                        ? item?.inActiveIco
                        : pathname === item?.path
                        ? item.activeIco
                        : item.inActiveIco
                    }
                    alt={item?.name}
                    width="24px"
                    height="24px"
                  />
                  {!isCollapsed && (
                    <CustomText
                      text={item.name}
                      color={
                        item?.path === pathname
                          ? "extsy.bg"
                          : "extsy.neutral300"
                      }
                      cursor="pointer"
                    />
                  )}
                </Flex>
              </Tooltip>
            ))}
          </Flex>
        </Stack>
      </Stack>
      <CustomModal
        isOpen={isLogoutOpen}
        onClose={onLogoutClose}
        onSubmit={() => {
          onLogoutClose();
          navigate("/auth/login");
        }}
        headerText={"Logout"}
        btnText={"Confirm, Logout"}
        closeBtntext={"Cancel"}
        width={"600px"}
        bgtopBlur="extsy.primary"
        noCloseFooter={true}
      >
        <Flex p="32px" flexDir="column" align="center">
          <CustomText text="Are you sure you want to end this session?" />
        </Flex>
      </CustomModal>
    </Flex>
  );
}

export default Sidebar;
