import { Text, Flex } from "@chakra-ui/react";
import React from "react";
import CustomText from "../../components/text/CustomText";
import Countdown from "react-countdown";
import { useTranslation } from "react-i18next";
import CustomVerificationInput from "../../components/form/CustomVerificationInput";
import PrimaryButton from "../../components/button/primaryButton";

function VerifyEmail({ onNext }: { onNext: () => void }) {
  const { t } = useTranslation();
  //   const [isLessThan768] = useMediaQuery("(max-height: 799px)");
  const renderer = ({ minutes, seconds, completed }: any) => {
    return (
      <Flex flexDir="column" gap="16px">
        <CustomText
          text={t("Time remaining")}
          align="center"
          color="extsy.baseWhite"
        />
        <Flex justify="center" align="center" gap="5px">
          <Flex
            align="center"
            justify="center"
            bg="extsy.neutral1000"
            borderRadius="8px"
            px="10px"
            h="40px"
            color="extsy.primary"
            fontFamily={"Metropolis"}
          >
            {"0" + minutes}
          </Flex>
          <Text
            align="center"
            color="extsy.primary"
            fontSize="26px"
            fontWeight="500"
          >
            :
          </Text>
          <Flex
            align="center"
            justify="center"
            bg="extsy.neutral1000"
            borderRadius="8px"
            px="10px"
            h="40px"
            color="extsy.primary"
            fontFamily={"Metropolis"}
          >
            {seconds < 10 ? "0" + seconds : seconds}
          </Flex>
        </Flex>
        {completed && (
          <CustomText
            text={t("Resend OTP")}
            align="center"
            color="extsy.baseWhite"
          />
        )}
      </Flex>
    );
  };

  return (
    <>
      <Flex w="100%" flexDir={"column"} gap={"64px"}>
        <Flex align="center" flexDir="column" gap="32px">
          <CustomText
            text={t("Enter Verification Code")}
            family="Titillium Web"
            size="24px"
            lh="28px"
            weight="700"
            align="center"
            color="extsy.baseWhite"
          />
          <CustomText
            text={
              <Text color="extsy.neutral300">
                {t("Enter the 6 digits code we have sent you on")}{" "}
                <Text as="span" color="extsy.baseWhite">
                  user@email.com
                </Text>
              </Text>
            }
            align="center"
            color="extsy.neutral300"
          />
        </Flex>
        <Flex flexDir="column" align="center" justify="center" gap="40px">
          <CustomVerificationInput placeholder={0} />
          <Countdown
            date={Date.now() + 70000}
            renderer={renderer}
            key={Date.now()}
          />
        </Flex>
        <Flex flexDir="column" gap="24px" align="center">
          <PrimaryButton title={t("Verify")} onClick={onNext} />
        </Flex>
      </Flex>
    </>
  );
}

export default VerifyEmail;
