import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import StatsTable from "./statsTable";

const Stats = () => {
  return (
    <Grid
      gap={{ base: "8px", md: "24px" }}
      // Responsive grid layout
      templateColumns={{
        base: "1fr", // 1 column on mobile
        sm: "repeat(12, 1fr)", // 2 columns on small screens
      }}
    >
      <GridItem
        gridColumn="span 12" // Full width for all screen sizes
      >
        <StatsTable />
      </GridItem>
    </Grid>
  );
};

export default Stats;
