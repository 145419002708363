import {
  Flex,
  Image,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import ArrowBack from "../../assets/images/black-left.svg";
import ArrowForward from "../../assets/images/black-right.svg";
import EmptyTrxIco from "../../assets/images/empty-trx-ico.svg";
import DeleteIcon from "../../assets/images/delete-icon.svg";
import CustomText from "../../components/text/CustomText";
import GradientText from "../../components/text/GradientText";

import PrimaryButton from "../button/primaryButton";
import ArchiveIcon from "../../assets/images/archive.svg";
import DocumentCopy from "../../assets/images/document-copy.svg";
import EditIcon from "../../assets/images/edit.svg";
import { REF_LINKS } from "../../utils/constants";
import { IconPlus } from "@tabler/icons-react";
import CustomModal from "../modal/customModal";
import CustomInput from "../form/CustomInput";

const LinkTable = () => {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  return (
    <Flex
      p="24px"
      gap="16px"
      flexDir="column"
      bg="extsy.neutral1000"
      borderRadius="24px"
      boxShadow="0px 3px 4px 0px rgba(0, 0, 0, 0.03)"
    >
      <Flex align={"center"} gap={"10px"} justify="space-between" pb="20px">
        <CustomText
          text={t("Referral Links")}
          size={{ base: "16px", md: "24px" }}
          weight="700"
          color="extsy.baseWhite"
          family="Titillium Web"
        />
        <PrimaryButton
          title="Add New Link"
          width="fit-content"
          padding={{ base: "8px 16px", md: "10px 32px" }}
          height="38px"
          weight="600"
          fontSize={{ base: "12px", md: "16px" }}
          onClick={onOpen}
          icon2={<IconPlus width={"24px"} />}
        />
      </Flex>
      {REF_LINKS?.length === 0 ? (
        <Flex
          minH={"401px"}
          flexDir="column"
          justify="center"
          align="center"
          gap="8px"
        >
          <Image alt="empty" src={EmptyTrxIco} />
          <CustomText
            text={t("no Data Available")}
            size="14px"
            color="extsy.neutral400"
          />
        </Flex>
      ) : (
        <TableContainer minH={"300px"}>
          <Table border="1px solid" color="extsy.neutral900">
            <Thead bg="extsy.neutral900" height="40px">
              <Tr>
                <Th borderColor="extsy.neutral900" maxW="160px" minW="160px">
                  <Flex align="center" gap="4px">
                    <CustomText
                      text={t("Wallet Address")}
                      size="13px"
                      color="extsy.baseWhite"
                      weight="500"
                      ls="-0.13px"
                    />
                  </Flex>
                </Th>
                <Th borderColor="extsy.neutral900" maxW="283px" minW="160px">
                  <Flex align="center" gap="4px">
                    <CustomText
                      text={t("Action")}
                      size="13px"
                      color="extsy.baseWhite"
                      weight="500"
                      ls="-0.13px"
                    />
                  </Flex>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {REF_LINKS.map((link, index) => (
                <Tr key={index}>
                  <Td borderColor="extsy.neutral900" borderRight="1px solid">
                    <CustomText text={link?.link} size="14px" />
                  </Td>

                  <Td borderColor="extsy.neutral900" borderRight="1px solid">
                    <Flex align={"center"} gap={"16px"}>
                      <Image
                        src={ArchiveIcon}
                        alt="tick or cross"
                        onClick={onDeleteOpen}
                      />
                      <Image src={DocumentCopy} alt="tick or cross" />
                      <Image src={EditIcon} alt="tick or cross" />
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      <Flex gap="16px" px="20px" py="14px" align="center" justify="flex-end">
        <CustomText
          text={`1-10 ${t("of")} 50`}
          size="13px"
          weight="500"
          color="extsy.neutral300"
        />
        <Flex gap="8px">
          <Image src={ArrowBack} alt="arrow-back" cursor="pointer" />
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <GradientText text="1" size="14px" weight="500" bg="extsy.g1" />
          </Flex>
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <CustomText text="2" size="14px" weight="500" />
          </Flex>
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <CustomText text="3" size="14px" weight="500" />
          </Flex>
          <Image src={ArrowForward} alt="arrow-forward" cursor="pointer" />
        </Flex>
      </Flex>
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={() => {
          onClose();
        }}
        headerText="Add New Referral Link"
        // noHeader={true}
        // noFooter={true}
        // width={"508px"}
        btnText="Save"
        noCloseFooter={true}
        btnContinueWidth="400px"
        bgtopBlur="extsy.primary"
      >
        <Stack
          gap={"32px"}
          justify={"center"}
          align={"center"}
          my={"24px"}
          mx={{ base: "20px", md: "40px" }}
        >
          <CustomText text="Please enter the details below!" lh="120%" />
          <Stack gap={"16px"} w={"100%"}>
            <Stack gap={"12px"} align={"flex-start"}>
              <CustomText text="Wallet Address" size="14px" lh="120%" />
              <CustomInput placeholder="Enter Referral Link Name" />
            </Stack>
            <Stack gap={"12px"} w={"100%"} align={"flex-start"}>
              <CustomText text="Referral Link" size="14px" lh="120%" />

              <CustomInput
                placeholder="Referral Link"
                value="https://extsy.app.link/referral?link_id=4a4f83d630eeec"
              />
            </Stack>
          </Stack>
        </Stack>
      </CustomModal>
      <CustomModal
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        onSubmit={() => {
          onDeleteClose();
        }}
        noHeader={true}
        footerText="Confirm Delete"
        closeBtntext="Not Now"
        bgtopBlur="extsy.primary"
      >
        <Stack
          gap={"32px"}
          justify={"center"}
          align={"center"}
          my={"56px"}
          mx={{ base: "20px", md: "40px" }}
        >
          <Image src={DeleteIcon} alt="delete icon" />
          <CustomText
            text="Delete Referral Link"
            color="extsy.baseWhite"
            family="Power Grotesk"
            size="24px"
          />
          <CustomText text="Are you sure you want to delete the referral link!" />
        </Stack>
      </CustomModal>
    </Flex>
  );
};

export default LinkTable;
