import { Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../components/text/CustomText";
import EyeIcon from "../../assets/images/eye.svg";
import { LoginPayload } from "../../utils/types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../components/button/primaryButton";
import AuthCustomInput from "../../components/form/AuthCustomInput";

function UpdatePassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  //   const [isLessThan768] = useMediaQuery("(max-height: 799px)");
  const [payload, setPayload] = useState<LoginPayload>({
    email: "",
    password: "",
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <Flex w="100%" flexDir={"column"} gap={"64px"}>
      <Flex align="center" flexDir="column" gap="32px">
        <CustomText
          text={t("Add New Password")}
          family="Titillium Web"
          size="24px"
          lh="24px"
          weight="400"
          align="center"
          color="extsy.baseWhite"
        />
        <CustomText
          text={t(
            "Choose a strong & secure alpha-numeric password with special characters minimum length of 8."
          )}
          align="center"
          color="extsy.neutral300"
          lh="19px"
        />
      </Flex>
      <Flex flexDir="column" gap="24px">
        <Flex flexDir="column">
          <CustomText
            text={t("New Password")}
            color="extsy.neutral500"
            size="12px"
          />
          <AuthCustomInput
            placeholder="**************"
            value={payload.password}
            onChange={(e: any) =>
              setPayload({ ...payload, password: e.target.value })
            }
            onIconClick={() => {
              setIsPasswordVisible(!isPasswordVisible);
            }}
            type={isPasswordVisible ? "text" : "password"}
            rightIco={EyeIcon}
          />
        </Flex>
        <Flex flexDir="column">
          <CustomText
            text={t("Repeat Password")}
            color="extsy.neutral500"
            size="12px"
          />
          <AuthCustomInput
            placeholder="**************"
            value={payload.password}
            onChange={(e: any) =>
              setPayload({ ...payload, password: e.target.value })
            }
            type={isPasswordVisible ? "text" : "password"}
            rightIco={EyeIcon}
            onIconClick={() => {
              setIsPasswordVisible(!isPasswordVisible);
            }}
          />
        </Flex>
      </Flex>
      <Flex flexDir="column" gap="24px" align="center">
        <PrimaryButton
          title={t("Update Password")}
          onClick={() => {
            navigate("/auth/security-code");
          }}
        />
      </Flex>
    </Flex>
  );
}

export default UpdatePassword;
