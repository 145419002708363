import { Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import MineCryptoIcon from "../../assets/images/mine-crypto-ico.svg";
import CustomText from "../text/CustomText";

const CustomCard = ({ children, icon, title, img }: any) => {
  return (
    <Flex
      p={"40px"}
      borderRadius="24px"
      bg="extsy.neutral1000"
      minH="220px"
      justify="space-between"
      flexDir="column"
      overflow="hidden"
      pos="relative"
    >
      <Box
        display={{ base: "block", md: "none" }}
        height="419px"
        width="419px"
        background="extsy.g1"
        opacity="0.6"
        filter="blur(92px)"
        borderRadius="419px"
        pos="absolute"
        left="0"
        top="0"
        ml="-331px"
        mt="-379px"
      />
      <Flex align={"center"} gap={"8px"}>
        <CustomText text={title} color="extsy.baseWhite" />
        <Image src={icon} />
      </Flex>
      {children}

      {img && (
        <Image
          src={MineCryptoIcon}
          alt="mine-crypto"
          pos={{ md: "absolute" }}
          top="0"
          draggable="false"
          right="0"
          zIndex="10"
        />
      )}
      {/* {img && (
        <Box
          width="430px"
          height="524px"
          bg="extsy.secondary100"
          filter="blur(197px)"
          pos="absolute"
          right="0"
          bottom="0"
          mb="-344px"
          mr="-361px"
          zIndex="1"
        />
      )} */}
    </Flex>
  );
};

export default CustomCard;
