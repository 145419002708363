import {
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { IconChevronDown } from "@tabler/icons-react";
import React from "react";
import CustomText from "../text/CustomText";
const CoinDropDown = ({ img }: any) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        colorScheme="blackAlpha"
        bg="#272727"
        color="white"
        _hover={{ bg: "#333333" }}
        _expanded={{ bg: "#333333" }}
        // px={4}
        w={{ base: "106px", md: "100%" }}
        h={"56px"}
        borderRadius="16px"
        px={{ base: "12px", md: "19.5px" }}
        py={{ base: "6px", md: "9.5px" }}
        display={"flex"}
        alignItems={"center"}
        gap={"10px"}
        //   onClick={onClick}
      >
        <Flex align={"center"} gap={"10px"}>
          <Image src={img} boxSize={{ base: "16px", md: "24px" }} />
          <CustomText text="BTC" size="12px" lh="120%" />
          <IconChevronDown width={"16px"} />
        </Flex>
      </MenuButton>
      <MenuList bg={"#272727"} border={"none"}>
        <MenuItem bg={"#272727"}>
          <Flex align={"center"} gap={"10px"}>
            <CustomText text={"BTC"} />
            <Image src={img} alt="img" boxSize="22px" />
          </Flex>
        </MenuItem>
        <MenuItem bg={"#272727"}>
          <Flex align={"center"} gap={"10px"}>
            <CustomText text={"BTC"} />
            <Image src={img} alt="img" boxSize="22px" />
          </Flex>
        </MenuItem>
        <MenuItem bg={"#272727"}>
          <Flex align={"center"} gap={"10px"}>
            <CustomText text={"BTC"} />
            <Image src={img} alt="img" boxSize="22px" />
          </Flex>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default CoinDropDown;
