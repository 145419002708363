import { Flex, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../components/text/CustomText";
import { LoginPayload } from "../../utils/types";
import GradientText from "../../components/text/GradientText";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../components/button/primaryButton";
import AuthCustomInput from "../../components/form/AuthCustomInput";

const Enteremail = ({ onNext }: { onNext: () => void }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  // const [isLessThan768] = useMediaQuery("(max-height: 799px)");
  const [payload, setPayload] = useState<LoginPayload>({
    email: "",
    password: "",
  });
  return (
    <Flex w="100%" flexDir={"column"} gap={"64px"}>
      <Flex align="center" flexDir="column" gap="32px">
        <CustomText
          text={t("Enter Your Email ID")}
          family="Titillium Web"
          size="24px"
          lh="24px"
          weight="400"
          align="center"
          color="extsy.baseWhite"
        />
        <CustomText
          text={
            <Text color="extsy.neutral300">
              {t("Enter your registered email ID to receive a")}{" "}
              <Text as="span" color="extsy.baseWhite">
                {t("Verification code.")}
              </Text>
            </Text>
          }
          align="center"
          color="extsy.neutral300"
          fontWeight={"600"}
          mx={"8px"}
          lh={"20px"}
        />
      </Flex>

      <Flex flexDir="column" pb={"50px"}>
        <Flex flexDir="column">
          <CustomText text={t("Email")} color="extsy.neutral500" size="12px" />
          <AuthCustomInput
            placeholder={t("Enter your email")}
            type="email"
            color={
              payload.email === "jhond@gmail.com" ? "#ED3D4E" : "extsy.text"
            }
            value={payload.email}
            onChange={(e: any) =>
              setPayload({ ...payload, email: e.target.value })
            }
          />
        </Flex>
      </Flex>

      <Flex flexDir="column" gap="24px" align="center">
        <PrimaryButton title={t("Verification Code")} onClick={onNext} />
        <Flex align="center" gap="4px">
          <CustomText
            text={t("Remember Password?")}
            color="extsy.neutral300"
            lh="19px"
          />
          <GradientText
            text={t("Sign Up")}
            weight="600"
            bg="extsy.g1"
            lh="22px"
            cursor="pointer"
            onClick={() => {
              navigate("/auth/signup");
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Enteremail;
