import {
  Box,
  Flex,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useOutsideClick,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomText from "../text/CustomText";
import TableSearchInput from "../form/TableSearchInput";
import {
  IconCalendar,
  IconCheck,
  IconChevronDown,
  IconChevronUp,
} from "@tabler/icons-react";
import EmptyTrxIco from "../../assets/images/empty-trx-ico.svg";
import ArrowBack from "../../assets/images/black-left.svg";
import ArrowForward from "../../assets/images/black-right.svg";
import FilterIcon from "../../assets/images/filter.svg";
import GradientText from "../text/GradientText";
import { SWAPS } from "../../utils/constants";
import PrimaryButton from "../button/primaryButton";
import CustomCalendar from "../form/CustomCalendar";

function Swaps({ minH, mt, fromSwap }: any) {
  const { t } = useTranslation();

  const filterRef = useRef(null);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("thisWeek");
  const [dateRange, setDateRange] = useState([new Date(), new Date()]); // For range selection

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const placeholderText = useBreakpointValue({
    base: "Search Transaction",
    md: t("Search for exchange ID, Address or tx hash"),
  });
  const FILTER_ITEMS = [
    {
      id: "thisWeek",
      name: t("thisWeek"),
    },
    {
      id: "last14Days",
      name: t("last14Days"),
    },
    {
      id: "lastMonth",
      name: t("lastMonth"),
    },
    {
      id: "last2Month",
      name: t("last2Month"),
    },
  ];

  const getBgColor = (status: string) => {
    switch (status) {
      case "Completed":
        return "#56CE77";
      case "In Process":
        return "#FFA333";
      case "Cancelled":
        return "#FF6574";
      default:
        return "extsy.neutral300";
    }
  };

  const formatDateRange = (range: [Date, Date]) => {
    const options: any = { day: "2-digit", month: "short", year: "numeric" };
    const startDate = range[0]
      .toLocaleDateString("en-US", options)
      .replace(/,/g, "");
    const endDate = range[1]
      .toLocaleDateString("en-US", options)
      .replace(/,/g, "");
    return `${startDate} - ${endDate}`;
  };
  useOutsideClick({
    ref: filterRef,
    handler: () => {
      setIsFilterOpen(false);
    },
  });

  return (
    <Flex
      p="24px"
      gap="16px"
      flexDir="column"
      bg="extsy.neutral1000"
      borderRadius="24px"
      boxShadow="0px 3px 4px 0px rgba(0, 0, 0, 0.03)"
      marginTop={mt || ""}
    >
      <Flex
        align={{ base: "flex-start", md: "center" }}
        gap={"10px"}
        justify="space-between"
        pb="20px"
        flexDirection={{ base: "column", md: "row" }}
      >
        <Flex align={"center"} justify={"space-between"} w={"100%"}>
          <CustomText
            text={t("Swaps")}
            size="24px"
            weight="700"
            color="extsy.baseWhite"
            family="Titillium Web"
          />
          {fromSwap && (
            <Box display={{ base: "block", md: "none" }}>
              <PrimaryButton
                title="Export .CSV"
                size="14px"
                color="extsy.bg"
                height="38px"
                maxW="134px"
              />
            </Box>
          )}
        </Flex>
        <Flex
          align={{ md: "center" }}
          gap="10px"
          flexDirection={
            fromSwap ? { base: "column-reverse", md: "row" } : "row"
          }
          w={{ base: "100%", md: "fit-content" }}
        >
          <TableSearchInput
            placeholder={placeholderText}
            minW={{ base: "172px", md: "336px" }}
          />
          {fromSwap ? (
            <>
              <Box pos="relative">
                <Flex
                  cursor="pointer"
                  gap="16px"
                  justify={"space-between"}
                  align="center"
                  borderRadius="666px"
                  minW={{ base: "112px", md: "230px" }}
                  bg="extsy.neutral900"
                  height="38px"
                  ps={{ base: "16px", md: "24px" }}
                  pe="10px"
                  onClick={() => setIsCalendarOpen(!isCalendarOpen)}
                >
                  <CustomText
                    cursor
                    text={formatDateRange(dateRange as [Date, Date])} // Display selected date range
                    size="12px"
                    color="extsy.baseWhite"
                  />
                  <IconCalendar size="16px" color="#fafafa" />
                </Flex>
                <CustomCalendar
                  isCalendarOpen={isCalendarOpen}
                  value={dateRange}
                  onChange={(range: any) => {
                    setDateRange(range); // Update the date range
                    setIsCalendarOpen(false); // Close calendar
                  }}
                  isRangePicker={true} // Enable range picker
                />
              </Box>
              <Box display={{ base: "none", md: "block" }}>
                <PrimaryButton
                  title="Export .CSV"
                  size="14px"
                  color="extsy.bg"
                  height="38px"
                  maxW="134px"
                />
              </Box>
            </>
          ) : (
            <Box pos="relative">
              <Flex
                height="38px"
                minW={{ base: "112px", md: "149px" }}
                align="center"
                justify="space-between"
                cursor="pointer"
                borderRadius="666px"
                bg="extsy.neutral900"
                boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
                ps={{ base: "16px", md: "24px" }}
                pe="10px"
                onClick={() => {
                  setIsFilterOpen(!isFilterOpen);
                }}
              >
                <CustomText
                  cursor="pointer"
                  text={t(activeFilter)}
                  size="12px"
                  color="extsy.baseWhite"
                />
                {isFilterOpen ? (
                  <IconChevronUp color="#FAFAFA" size="16px" />
                ) : (
                  <IconChevronDown color="#FAFAFA" size="16px" />
                )}
              </Flex>
              {isFilterOpen && (
                <Flex
                  flexDir="column"
                  gap="12px"
                  p="16px"
                  borderRadius="16px"
                  border="1px solid"
                  borderColor="extsy.neutral800"
                  bg="extsy.neutral900"
                  minW={{ base: "100px", md: "150px" }}
                  pos="absolute"
                  top="0"
                  marginTop="50px"
                  ref={filterRef}
                >
                  {FILTER_ITEMS.map((item, index) => (
                    <Flex
                      align="center"
                      justify="space-between"
                      py="8px"
                      cursor="pointer"
                      key={index}
                      onClick={() => {
                        setIsFilterOpen(false);
                        setActiveFilter(item.id);
                      }}
                    >
                      <CustomText
                        text={item?.name}
                        size="14px"
                        color={
                          item?.id === activeFilter
                            ? "extsy.baseWhite"
                            : "extsy.neutral300"
                        }
                        cursor="pointer"
                      />
                      {item?.id === activeFilter && (
                        <IconCheck size="16px" color="#FAFAFA" />
                      )}
                    </Flex>
                  ))}
                </Flex>
              )}
            </Box>
          )}
        </Flex>
      </Flex>
      {activeFilter === "thisWeek" && !fromSwap ? (
        <Flex
          minH={minH || ""}
          flexDir="column"
          justify="center"
          align="center"
          gap="8px"
        >
          <Image alt="empty" src={EmptyTrxIco} />
          <CustomText
            text={t("noHistoryFound")}
            size="14px"
            color="extsy.neutral400"
          />
        </Flex>
      ) : (
        <TableContainer minH={minH || ""}>
          <Table border="1px solid" color="extsy.neutral900">
            <Thead bg="extsy.neutral900" height="40px">
              <Tr>
                <Th borderColor="extsy.neutral900" maxW="283px" minW="150px">
                  <Flex align="center" gap="4px">
                    <CustomText
                      text={t("status")}
                      size="13px"
                      color="extsy.baseWhite"
                      weight="500"
                      ls="-0.13px"
                    />
                    <Flex flexDir="column">
                      <Image src={FilterIcon} alt="filter" />
                    </Flex>
                  </Flex>
                </Th>
                <Th borderColor="extsy.neutral900" maxW="160px" minW="160px">
                  <Flex align="center" gap="4px">
                    <CustomText
                      text={t("Created")}
                      size="13px"
                      color="extsy.baseWhite"
                      weight="500"
                      ls="-0.13px"
                    />
                  </Flex>
                </Th>
                <Th borderColor="extsy.neutral900" maxW="283px" minW="160px">
                  <Flex align="center" gap="4px">
                    <CustomText
                      text={t("From")}
                      size="13px"
                      color="extsy.baseWhite"
                      weight="500"
                      ls="-0.13px"
                    />
                    <Flex flexDir="column">
                      <Image src={FilterIcon} alt="filter" />
                    </Flex>
                  </Flex>
                </Th>
                <Th borderColor="extsy.neutral900" maxW="283px" minW="160px">
                  <Flex align="center" gap="4px">
                    <CustomText
                      text={t("To")}
                      size="13px"
                      color="extsy.baseWhite"
                      weight="500"
                      ls="-0.13px"
                    />
                    <Flex flexDir="column">
                      <Image src={FilterIcon} alt="filter" />
                    </Flex>
                  </Flex>
                </Th>
                <Th borderColor="extsy.neutral900" maxW="283px" minW="160px">
                  <Flex align="center" gap="4px">
                    <CustomText
                      text={t("Equivalent")}
                      size="13px"
                      color="extsy.baseWhite"
                      weight="500"
                      ls="-0.13px"
                    />
                  </Flex>
                </Th>
                <Th borderColor="extsy.neutral900" maxW="283px" minW="160px">
                  <Flex align="center" gap="4px">
                    <CustomText
                      text={t("Profit")}
                      size="13px"
                      color="extsy.baseWhite"
                      weight="500"
                      ls="-0.13px"
                    />
                  </Flex>
                </Th>
                <Th borderColor="extsy.neutral900" maxW="283px" minW="160px">
                  <Flex align="center" gap="4px">
                    <CustomText
                      text={t("Profit, USD")}
                      size="13px"
                      color="extsy.baseWhite"
                      weight="500"
                      ls="-0.13px"
                    />
                  </Flex>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {SWAPS.map((trx, index) => (
                <Tr key={index}>
                  <Td
                    borderColor="extsy.neutral900"
                    borderRight="1px solid"
                    borderLeft="1px solid"
                  >
                    <Flex
                      w="90px"
                      h="24px"
                      align="center"
                      justify="center"
                      borderRadius="30px"
                      bg={`${getBgColor(trx?.status)}1A`}
                    >
                      <CustomText
                        text={trx.status}
                        size="10px"
                        color={getBgColor(trx?.status)}
                      />
                    </Flex>
                  </Td>
                  <Td borderColor="extsy.neutral900" borderRight="1px solid">
                    <CustomText text={trx.date} size="14px" />
                  </Td>
                  <Td borderColor="extsy.neutral900" borderRight="1px solid">
                    <Flex align={"center"} gap={"22px"} minW={"200px"}>
                      <Image src={trx.fromImg} alt="crypto Img" w={"32px"} />
                      <Flex align={"center"} gap={"8px"}>
                        <CustomText text={trx.fromCrypto} size="14px" />
                        <CustomText text={"|"} size="14px" />
                        <CustomText text={trx.fromSymbol} size="14px" />
                      </Flex>
                    </Flex>
                  </Td>
                  <Td borderColor="extsy.neutral900" borderRight="1px solid">
                    <Flex align={"center"} gap={"22px"} minW={"200px"}>
                      <Image src={trx.toImg} alt="crypto Img" w={"32px"} />
                      <Flex align={"center"} gap={"12px"}>
                        <CustomText text={trx.toCrypto} size="14px" />
                        |
                        <CustomText text={trx.toSymbol} size="14px" />
                      </Flex>
                    </Flex>
                  </Td>
                  <Td borderColor="extsy.neutral900" borderRight="1px solid">
                    <CustomText text={trx.equivalent} size="14px" />
                  </Td>
                  <Td borderColor="extsy.neutral900" borderRight="1px solid">
                    <CustomText text={trx.profit} size="14px" />
                  </Td>
                  <Td borderColor="extsy.neutral900" borderRight="1px solid">
                    <CustomText text={trx.profitUSD} size="14px" />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      {SWAPS.length > 0 && (
        <Flex gap="16px" px="20px" py="14px" align="center" justify="flex-end">
          <CustomText
            text={`1-10 ${t("of")} 50`}
            size="13px"
            weight="500"
            color="extsy.neutral300"
          />
          <Flex gap="8px">
            <Image src={ArrowBack} alt="arrow-back" cursor="pointer" />
            <Flex
              flexDir="column"
              align="center"
              justify="center"
              bg="extsy.neutral900"
              borderRadius="6px"
              height="30px"
              width="30px"
            >
              <GradientText text="1" size="14px" weight="500" bg="extsy.g1" />
            </Flex>
            <Flex
              flexDir="column"
              align="center"
              justify="center"
              bg="extsy.neutral900"
              borderRadius="6px"
              height="30px"
              width="30px"
            >
              <CustomText text="2" size="14px" weight="500" />
            </Flex>
            <Flex
              flexDir="column"
              align="center"
              justify="center"
              bg="extsy.neutral900"
              borderRadius="6px"
              height="30px"
              width="30px"
            >
              <CustomText text="3" size="14px" weight="500" />
            </Flex>
            <Image src={ArrowForward} alt="arrow-forward" cursor="pointer" />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}

export default Swaps;
