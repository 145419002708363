import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import CustomCard from "../../components/card/customCard";
import InformIcon from "../../assets/images/information.svg";
import EstimatedReward from "../../components/card/EstimatedReward";
import TotalSwaps from "../../components/card/TotalSwaps";
import Swaps from "../../components/dashboard/Swaps";

function Dashboard() {
  return (
    <Grid
      gap={{ base: "8px", md: "24px" }}
      templateColumns={{
        base: "1fr", // 1 column for mobile
        sm: "repeat(12, 1fr)", // 2 columns for small screens
      }}
    >
      {/* Estimated Reward Card */}
      <GridItem
        gridColumn={{
          base: "span 12", // Takes 1 column on mobile
          md: "span 6", // Takes 6 columns on medium screens

          xl: "span 3",
        }}
      >
        <CustomCard icon={InformIcon} title="Estimated Reward" img={false}>
          <EstimatedReward value1="~0.00" cur1="BTC" val2="~0.00" cur2="USD" />
        </CustomCard>
      </GridItem>

      {/* Total Swaps Card */}
      <GridItem
        gridColumn={{
          base: "span 12",
          md: "span 6",

          xl: "span 3",
        }}
      >
        <CustomCard icon={InformIcon} title="Total Swaps" img={false}>
          <TotalSwaps value1="0" cur1="(0)" val2="Day" cur2="Week" />
        </CustomCard>
      </GridItem>

      {/* Ready for Payout Card */}
      <GridItem
        gridColumn={{
          base: "span 12",
          xl: "span 6",
        }}
      >
        <CustomCard title="Ready for payout" img={true}>
          <EstimatedReward value1="0.00" cur1="BTC" val2="0.00" cur2="USD" />
        </CustomCard>
      </GridItem>

      {/* Swaps Section */}
      <GridItem gridColumn="span 12">
        <Swaps minH={{ base: "254px", md: "401px" }} />
      </GridItem>
    </Grid>
  );
}

export default Dashboard;
