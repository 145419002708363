import { Text } from "@chakra-ui/react";
import React from "react";

function GradientText({
  text,
  family,
  size,
  weight,
  lh,
  transform,
  wrap,
  align,
  bg,
  onClick,
  cursor,
  noOfLines,
  mt,
}: any) {
  return (
    <Text
      fontFamily={family || "Metropolis"}
      fontSize={size || "16px"}
      fontWeight={weight || "400"}
      lineHeight={lh || "16px"}
      textTransform={transform || "none"}
      whiteSpace={wrap || "none"}
      textAlign={align || "left"}
      bg={bg || "extsy.neutral300"}
      bgClip="text"
      onClick={onClick}
      cursor={cursor || "auto"}
      noOfLines={noOfLines || "none"}
      mt={mt || "0"}
    >
      {text}
    </Text>
  );
}

export default GradientText;
