import { Box, Center, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import OtpTickIcon from "../../assets/images/otp-tick-icon.svg";
import CustomText from "../../components/text/CustomText";
import CustomVerificationInput from "../../components/form/CustomVerificationInput";
import Countdown from "react-countdown";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../components/button/primaryButton";

const VerificationCode = () => {
  const { t } = useTranslation();
  const Completionist = () => (
    <CustomText text={t("resendOTP")} align="center" color="extsy.baseWhite" />
  );
  const renderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <Flex flexDir="column" gap="16px">
          <CustomText
            text={t("Resend email again in")}
            align="center"
            color="extsy.baseWhite"
          />
          <Flex justify="center" align="center" gap="5px">
            <Flex
              align="center"
              justify="center"
              bg="extsy.neutral1000"
              borderRadius="8px"
              px="10px"
              h="40px"
              color="extsy.primary"
              fontFamily={"Metropolis"}
            >
              {"0" + minutes}
            </Flex>
            <Text
              align="center"
              color="extsy.primary"
              fontSize="26px"
              fontWeight="500"
            >
              :
            </Text>
            <Flex
              align="center"
              justify="center"
              bg="extsy.neutral1000"
              borderRadius="8px"
              px="10px"
              h="40px"
              color="extsy.primary"
              fontFamily={"Metropolis"}
            >
              {seconds < 10 ? "0" + seconds : seconds}
            </Flex>
          </Flex>
        </Flex>
      );
    }
  };

  return (
    <Center
      p={"56px 40px"}
      flexDirection={"column"}
      borderRadius={"24px"}
      bg={"rgba(255,255,255,0.03)"}
      backdropFilter={"blur(12px)"}
      maxW={{ base: "358px", md: "600px" }}
      //   mb={"60px"}
      gap={"32px"}
    >
      <Image src={OtpTickIcon} alt="tick" w={{ base: "55px", md: "80px" }} />
      <CustomText
        text="Check your email"
        color="extsy.baseWhite"
        family="Power Grotesk"
        size="24px"
      />
      {/* <Flex align={"center"} gap={"5px"} w={"100%"}> */}
      <CustomText
        text={
          <>
            Enter the 6 digits code we have sent you on{" "}
            <span style={{ color: "#FAFAFA", fontSize: "16px" }}>
              user@email.com
            </span>
          </>
        }
        size={{ base: "14px", md: "16px" }}
        align="center"
      />

      {/* </Flex> */}
      <Flex flexDir="column" align="center" justify="center" gap="40px">
        <CustomVerificationInput placeholder={0} />
        <Countdown
          date={Date.now() + 120000}
          renderer={renderer}
          key={Date.now()}
        />
      </Flex>
      <Box mt={{ base: "90px", md: "0px" }} width={{ base: "100%", md: "75%" }}>
        <PrimaryButton title="Resend" isDisabled={true} color="#fff" />
      </Box>
    </Center>
  );
};

export default VerificationCode;
