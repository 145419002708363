import { Center, Flex, Stack } from "@chakra-ui/react";
import React from "react";
import CustomText from "../text/CustomText";

const SourceCode = () => {
  return (
    <Stack
      p={"32px"}
      gap={"32px"}
      borderRadius={"24px"}
      border={"1px solid #221f1f"}
      bg="extsy.neutral1000"
    >
      <Flex align={"center"} justify={"space-between"}>
        <CustomText
          text="Place the source code to your web page"
          family="Titillium Web"
          size={{ base: "16px", md: "24px" }}
          weight={{ base: "400", md: "700" }}
          lh="23.784px"
          color="extsy.baseWhite"
        />
        <Center
          p={"7px 13px"}
          gap={"10px"}
          borderRadius={"19px"}
          bg={"rgba(255,255,255,0.16)"}
        >
          <CustomText
            text="Copy to clipboard"
            family={{ base: "Metropolis", md: "Titillium Web" }}
            size={{ base: "12px", md: "18px" }}
            lh={{ base: "120%", md: "26px" }}
            color="extsy.baseWhite"
          />
        </Center>
      </Flex>
      <CustomText
        text={`<iframe id='iframe-widget' src='https://changenow.io/embeds/exchange-widget/v2/widget.html?FAQ=true&amount=0.1&amountFiat=1500&backgroundColor=FFFFFF&darkMode=false&from=btc&fromFiat=eur&horizontal=false&isFiat&lang=en-US&link_id=4a4f83d630513f&locales=true&logo=true&primaryColor=00C26F&to=eth&toFiat=eth&toTheMoon=true' style="height: 356px; width: 100%; border: none"></iframe> <script defer type='text/javascript' src='https://changenow.io/embeds/exchange-widget/v2/stepper-connector.js'></script>`}
        wb="break-word"
        color="extsy.baseWhite"
        lh="120%"
      />
    </Stack>
  );
};

export default SourceCode;
