import React from "react";
import { Box, Flex, Image, Stack } from "@chakra-ui/react";
import CustomText from "../text/CustomText";
import ArrowUp from "../../assets/images/arrow-up.svg";

const TotalSwaps = ({ value1, val2, cur1, cur2 }: any) => {
  return (
    <Stack gap={"16px"}>
      <Flex align="flex-end" gap="12px" flexWrap={"wrap"}>
        <Box pb="4px">
          <Image src={ArrowUp} alt="arrow" />
        </Box>
        <CustomText
          text={value1}
          size="56px"
          family="Titillium Web"
          ls="2.8px"
          color="extsy.secondary100"
        />
        <Box pb="7px">
          <CustomText
            text={cur1}
            size="24px"
            ls="1.2px"
            family="Titillium Web"
            color="extsy.neutral500"
          />
        </Box>
      </Flex>
      <Flex align={"center"} gap={"8px"}>
        <CustomText
          text={val2}
          size="16px"
          ls="0.8px"
          lh="120%"
          family="Titillium Web"
          color="extsy.baseWhite"
        />
        <CustomText
          text={cur2}
          size="16px"
          ls="0.8px"
          lh="120%"
          family="Titillium Web"
          color="extsy.neutral500"
        />
      </Flex>
    </Stack>
  );
};

export default TotalSwaps;
