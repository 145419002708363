// 1. Import `extendTheme`
import { extendTheme } from "@chakra-ui/react";
import "@fontsource/metropolis";
import "@fontsource/titillium-web";
import "@fontsource/courier-prime";
import "@fontsource/share-tech";

// 2. Call `extendTheme` and pass your custom values
export const theme = extendTheme({
  colors: {
    extsy: {
      dark: "#0E0E0E",
      dark100: "#1E1E1E",
      dark300: "#000F18",
      dark400: "#2B3139",
      dark500: "#252930",
      dark600: "#2A2D34",
      dark1000: "#0D0D0D",
      white: "#fff",
      bg: "#0B0A0A",
      danger: "#F6465D",
      primary: "#EFA3D1",
      primary100: "#FEF9CC",
      primary500: "#E35CAD",
      secondary: "#F0C2DB",
      secondary100: "#FEF8C3",
      text: "#626262",
      text2: "#EAECEF",
      text3: "#5E6673",
      text4: "#B7BDC6",
      baseWhite: "#fafafa",
      neutral50: "#F2F2F2",
      neutral100: "#8F92A1",
      neutral200: "#CFCECE",
      neutral300: "#BAB8B8",
      neutral500: "#908B8B",
      neutral600: "#7A7474",
      neutral800: "#4E4949",
      neutral900: "#383434",
      neutral700: "#655F5F",
      neutral1000: "#221F1F",
      neutral400: "#848E9C",
      g1: "linear-gradient(90deg, #FEF9CC 0%, #F6DBD4 40%, #F1C8D9 75%, #F0C2DB 100%)",
      g2: "linear-gradient(278deg, #FEF9CC 3.6%, #F6DBD4 58.44%, #F1C8D9 106.42%, #F0C2DB 140.69%)",
      g3: "linear-gradient(95deg, #000 0.91%, #212121 99.82%)",
      bgClose: "rgba(255, 255, 255, 0.10)",
      bgCard: "rgba(255, 255, 255, 0.03)",
      bgCardTopBlur: "#15B097",
      vCard: "rgba(0, 0, 0, 0.40)",
    },
  },
});
