import { Box, Center, Flex, Image } from "@chakra-ui/react";
import React from "react";
import { Outlet } from "react-router-dom";
import ExtsyBg from "../assets/images/auth-bg.svg";
import ExtsyBgDesign from "../assets/images/auth-bg-design.svg";
import LogoIcon from "../assets/images/logo-icon.svg";
// import AuthSlider from "../components/auth/AuthSlider";
function AuthLayout() {
  // const [isLessThan768] = useMediaQuery("(max-height: 768px)");
  // const [isLessThan1920] = useMediaQuery("(max-width: 1919px)");

  // useEffect(() => {
  //   if (isLessThan1920) {
  //     navigate("/coming-soon");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isLessThan1920]);

  return (
    <Flex
      bgImage={ExtsyBg}
      minH="100vh"
      position={"relative"}
      flexDirection={"column"}
    >
      <Box
        display={{ base: "none", md: "block" }}
        bgImage={ExtsyBgDesign}
        bgPosition={"center"}
        bgSize={"cover"}
        mt={"30px"}
        h={"550px"}
        position={"absolute"}
        right={0}
        bottom={0}
        w={"40%"}
      ></Box>
      <Flex
        w={"100%"}
        p={"24px 80px"}
        flexDirection={"column"}
        align={{ base: "center", md: "flex-start" }}
        gap={"10px"}
      >
        <Image src={LogoIcon} alt="logo" />
      </Flex>
      <Center h={"100%"} minH="85vh">
        <Outlet />
      </Center>
    </Flex>
  );
}

export default AuthLayout;
