import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import CustomCard from "../../components/card/customCard";
import Swaps from "../../components/dashboard/Swaps";
import SwapCard from "../../components/card/SwapCard";

const Swap = () => {
  return (
    <Grid
      gap={{ base: "8px", md: "24px" }}
      // Responsive grid layout
      templateColumns={{
        base: "1fr", // 1 column on mobile
        sm: "repeat(12, 1fr)", // 2 columns on small screens
      }}
    >
      {/* Total USD Equivalent Card */}
      <GridItem
        gridColumn={{
          base: "span 12", // 1 column on mobile
          md: "span 6", // 6 columns on medium screens
          lg: "span 4", // 4 columns on large screens
        }}
      >
        <CustomCard title="Total USD Equivalent" img={false}>
          <SwapCard value1="~0.00" cur1="USD" />
        </CustomCard>
      </GridItem>

      {/* Profit USD Equivalent Card */}
      <GridItem
        gridColumn={{
          base: "span 12",
          md: "span 6",
          lg: "span 4",
        }}
      >
        <CustomCard title="Profit USD Equivalent" img={false}>
          <SwapCard value1="~0.00" cur1="USD" />
        </CustomCard>
      </GridItem>

      {/* Profit BTC Equivalent Card */}
      <GridItem
        gridColumn={{
          base: "span 12",
          md: "span 12",
          lg: "span 4",
        }}
      >
        <CustomCard title="Profit BTC Equivalent" img={false}>
          <SwapCard value1="~0.00" cur1="BTC" />
        </CustomCard>
      </GridItem>

      {/* Swaps Section */}
      <GridItem
        gridColumn="span 12" // Full width for all screen sizes
      >
        <Swaps minH={"401px"} fromSwap={true} />
      </GridItem>
    </Grid>
  );
};

export default Swap;
