import BitcoinIco from "../assets/images/btc-ico.svg";
import EthIco from "../assets/images/eth-ico.svg";
import BnbIco from "../assets/images/bnb-ico.svg";
import SolIco from "../assets/images/sol-ico.svg";
import UsdtIco from "../assets/images/usdt-ico.svg";
import XrpIco from "../assets/images/xrp-ico.svg";
import USDIco from "../assets/images/usd-ico.svg";
import BahDinarIco from "../assets/images/bah-dinar-ico.svg";
import YuanIco from "../assets/images/yuan-ico.svg";
import AudIco from "../assets/images/aud-ico.svg";
import BitcoinIcon from "../assets/images/bitcoin-icon.svg";
import EtheremIcon from "../assets/images/ethereum-icon.svg";
import BinanceIcon from "../assets/images/binance-icon.svg";
import UsdIcon from "../assets/images/usd-icon.svg";
import RippleIcon from "../assets/images/ripple-icon.svg";
import DogeIcon from "../assets/images/dogecoin-icon.svg";
import PolygonIcon from "../assets/images/polygon-icon.svg";
import TetherIcon from "../assets/images/tether-icon.svg";
import StellerIcon from "../assets/images/steller-icon.svg";
import LitecoinIcon from "../assets/images/litecoin-icon.svg";
import DashboardActive from "../assets/images/dashboard/dashboard-active.svg";
import DashboardInactive from "../assets/images/dashboard/dashboard-inactive.svg";
import SwapInactive from "../assets/images/dashboard/swap-inactive.svg";
import SwapActive from "../assets/images/dashboard/swap-active.svg";
import StatsInactive from "../assets/images/dashboard/stats-inactive.svg";
import StatsActive from "../assets/images/dashboard/stats-active.svg";
import ProfitInactive from "../assets/images/dashboard/profit-inactive.svg";
import ProfitActive from "../assets/images/dashboard/profit-active.svg";
import WithdrawalInactive from "../assets/images/dashboard/withdrawal-inactive.svg";
import WithdrawalActive from "../assets/images/dashboard/withdrawal-active.svg";
import CoinInactive from "../assets/images/dashboard/coin-inactive.svg";
import CoinActive from "../assets/images/dashboard/coin-active.svg";
import PairInactive from "../assets/images/dashboard/pair-inactive.svg";
import PairActive from "../assets/images/dashboard/pair-active.svg";
import WidgetInactive from "../assets/images/dashboard/widget-inactive.svg";
import WidgetActive from "../assets/images/dashboard/widget-active.svg";
import SettingsActive from "../assets/images/dashboard/settings-active.svg";
import SettingsInactive from "../assets/images/dashboard/settings-inactive.svg";
import ChatInactive from "../assets/images/dashboard/chat-inative.svg";
import LogoutIco from "../assets/images/logout-ico.svg";

import TronIcon from "../assets/images/tron-icon.svg";
import CompletedIcon from "../assets/images/tick-circle.svg";
import NotCompletedIcon from "../assets/images/close-circle.svg";

export const DASHBOARD_MENU = [
  {
    path: "/",
    name: "dashboard",
    key: "dashboard",
    activeIco: DashboardActive,
    inActiveIco: DashboardInactive,
  },
  {
    path: "/swap",
    name: "swap",
    key: "swap",
    activeIco: SwapActive,
    inActiveIco: SwapInactive,
  },
  {
    path: "/stats",
    name: "stats",
    key: "stats",
    activeIco: StatsActive,
    inActiveIco: StatsInactive,
  },
  {
    path: "/profit",
    name: "Profit",
    key: "Profit",
    activeIco: ProfitActive,
    inActiveIco: ProfitInactive,
  },
  {
    path: "/withdrawal",
    name: "Withdrawal",
    key: "Withdrawal",
    activeIco: WithdrawalActive,
    inActiveIco: WithdrawalInactive,
  },
  {
    path: "/coin-status",
    name: "Coin Status",
    key: "Coin Status",
    activeIco: CoinActive,
    inActiveIco: CoinInactive,
  },
  {
    path: "/pair-status",
    name: "Pair Status",
    key: "Pair Status",
    activeIco: PairActive,
    inActiveIco: PairInactive,
  },
  {
    path: "/widget",
    name: "Widget",
    key: "Widget",
    activeIco: WidgetActive,
    inActiveIco: WidgetInactive,
  },
];

export const DASHBOARD_OTHER_MENU = [
  {
    path: "/settings",
    name: "settings",
    key: "settings",
    activeIco: SettingsActive,
    inActiveIco: SettingsInactive,
  },
  {
    path: "/help",
    name: "Help & Support",
    key: "Help & Support",
    activeIco: "",
    inActiveIco: ChatInactive,
    disabled: true,
  },
  {
    name: "Logout",
    key: "logout",
    inActiveIco: LogoutIco,
  },
];

export const AUTH_SLIDES_CONTENT = [
  {
    title: "Swap, Buy, Sell",
    description:
      "Swap, Buy, Sell, and Trade crypto assets effortlessly with our transparent, user-friendly platform. Enjoy seamless transactions, real-time data, and make informed decision-making.",
  },
  {
    title: "Extsy Pro",
    description:
      "Sign up for a free account to access a host of exclusive benefits and maximize your financial potential. Enjoy features designed to enhance your trading experience and optimize your returns, all while keeping your funds secure and under your control.",
  },
  {
    title: "Trading",
    description:
      "Enjoy tools designed to enhance your trading experience and optimize your returns, all while ensuring your portfolio remains secure and under your control.",
  },
];

export const CRYPTO_CURRENCIES = [
  {
    symbol: "BTC",
    name: "Bitcoin",
    icon: BitcoinIco,
    price: "$56,623.54",
  },
  {
    symbol: "ETH",
    name: "Ethereum",
    icon: EthIco,
    price: "$3,456.54",
  },
  {
    symbol: "BNB",
    name: "Binance",
    icon: BnbIco,
    price: "$456.54",
  },
  {
    symbol: "SOL",
    name: "Solana",
    icon: SolIco,
    price: "$156.54",
  },
  {
    symbol: "USDT",
    name: "Tether",
    icon: UsdtIco,
    price: "$1.00",
  },
  {
    symbol: "XRP",
    name: "Ripple",
    icon: XrpIco,
    price: "$1.00",
  },
];

export const FIAT_CURRENCIES = [
  {
    symbol: "USD",
    name: "US Dollar",
    icon: USDIco,
  },
  {
    symbol: "BHD",
    name: "Bahraini Dinar",
    icon: BahDinarIco,
  },
  {
    symbol: "CNY",
    name: "Chinese Yuan",
    icon: YuanIco,
  },
  {
    symbol: "AUD",
    name: "Australian Dollar",
    icon: AudIco,
  },
];

export const SWAPS = [
  {
    status: "Completed",
    date: "23 Jul, 2024",
    fromImg: BitcoinIcon, // Assuming you imported or defined these icon images
    fromSymbol: "Bitcoin",
    fromCrypto: "BTC",
    toImg: RippleIcon,
    toSymbol: "Ripple",
    toCrypto: "XRP",
    equivalent: "$639.930",
    profit: "0.012 BTC",
    profitUSD: "$639.930",
  },
  {
    status: "In Process",
    date: "24 Jul, 2024",
    fromImg: EtheremIcon,
    fromSymbol: "Ethereum",
    fromCrypto: "ETH",
    toImg: DogeIcon,
    toSymbol: "Dogecoin",
    toCrypto: "DOGE",
    equivalent: "$840.100",
    profit: "0.020 ETH",
    profitUSD: "$840.100",
  },
  {
    status: "Cancelled",
    date: "25 Jul, 2024",
    fromImg: BinanceIcon,
    fromSymbol: "Binance",
    fromCrypto: "BNB",
    toImg: TronIcon,
    toSymbol: "Tron",
    toCrypto: "TRX",
    equivalent: "$540.300",
    profit: "0.015 BNB",
    profitUSD: "$540.300",
  },
  {
    status: "Completed",
    date: "26 Jul, 2024",
    fromImg: UsdIcon,
    fromSymbol: "USD Coin",
    fromCrypto: "USDC",
    toImg: LitecoinIcon,
    toSymbol: "Litecoin",
    toCrypto: "LTC",
    equivalent: "$320.250",
    profit: "0.025 USDC",
    profitUSD: "$320.250",
  },
  {
    status: "In Process",
    date: "27 Jul, 2024",
    fromImg: RippleIcon,
    fromSymbol: "Ripple",
    fromCrypto: "XRP",
    toImg: StellerIcon,
    toSymbol: "Steller",
    toCrypto: "XLM",
    equivalent: "$150.700",
    profit: "0.010 XRP",
    profitUSD: "$150.700",
  },
  {
    status: "Cancelled",
    date: "28 Jul, 2024",
    fromImg: DogeIcon,
    fromSymbol: "Dogecoin",
    fromCrypto: "DOGE",
    toImg: PolygonIcon,
    toSymbol: "Polygon",
    toCrypto: "MATIC",
    equivalent: "$920.600",
    profit: "1.500 DOGE",
    profitUSD: "$920.600",
  },
  {
    status: "Completed",
    date: "29 Jul, 2024",
    fromImg: PolygonIcon,
    fromSymbol: "Polygon",
    fromCrypto: "MATIC",
    toImg: TetherIcon,
    toSymbol: "Tether",
    toCrypto: "USDT",
    equivalent: "$730.450",
    profit: "2.500 MATIC",
    profitUSD: "$730.450",
  },
];

export const STATS = [
  {
    date: "Total",
    Created: 10,
    Waiting: 2,
    Finished: 5,
    Failed: 1,
    turnover: "0 BTC",
    reward: "0 BTC",
  },
  {
    date: "22 Jul, 2024",
    Created: 4,
    Waiting: 2,
    Finished: 1,
    Failed: 1,
    turnover: "0.023 BTC",
    reward: "0.01 BTC",
  },
  {
    date: "17 Jul, 2024",
    Created: 0,
    Waiting: 0,
    Finished: 0,
    Failed: 0,
    turnover: "0 BTC",
    reward: "0.01 BTC",
  },
  {
    date: "10 Jul, 2024",
    Created: 2,
    Waiting: 0,
    Finished: 2,
    Failed: 0,
    turnover: "0.023 BTC",
    reward: "0.01 BTC",
  },
  {
    date: "06 Jul, 2024",
    Created: 0,
    Waiting: 0,
    Finished: 0,
    Failed: 0,
    turnover: "0 BTC",
    reward: "0.01 BTC",
  },
  {
    date: "10 Jul, 2024",
    Created: 2,
    Waiting: 0,
    Finished: 2,
    Failed: 0,
    turnover: "0.023 BTC",
    reward: "0.01 BTC",
  },
  {
    date: "06 Jul, 2024",
    Created: 0,
    Waiting: 0,
    Finished: 0,
    Failed: 0,
    turnover: "0.023 BTC",
    reward: "0.01 BTC",
  },
  {
    date: "06 Jul, 2024",
    Created: 0,
    Waiting: 0,
    Finished: 0,
    Failed: 0,
    turnover: "0.023 BTC",
    reward: "0.01 BTC",
  },
  {
    date: "06 Jul, 2024",
    Created: 1,
    Waiting: 0,
    Finished: 1,
    Failed: 0,
    turnover: "0.023 BTC",
    reward: "0.01 BTC",
  },
  {
    date: "06 Jul, 2024",
    Created: 0,
    Waiting: 0,
    Finished: 0,
    Failed: 0,
    turnover: "0.023 BTC",
    reward: "0.01 BTC",
  },
  {
    date: "06 Jul, 2024",
    Created: 1,
    Waiting: 0,
    Finished: 1,
    Failed: 0,
    turnover: "0.023 BTC",
    reward: "0.01 BTC",
  },
  {
    date: "06 Jul, 2024",
    Created: 1,
    Waiting: 0,
    Finished: 1,
    Failed: 0,
    turnover: "0.023 BTC",
    reward: "0.01 BTC",
  },
];

export const PAIRS = [
  {
    name: "ANT",
    price: "0.00010256",
    change: "-1.82%",
  },

  {
    name: "ETH",
    price: "0.00010256",
    change: "-0.34%",
  },
  {
    name: "BNB",
    price: "0.00010256",
    change: "+0.35%",
  },
  {
    name: "SOL",
    price: "0.00010256",
    change: "+1.30%",
  },
  {
    name: "USDT",
    price: "0.00010256",
    change: "-1.82%",
  },
  {
    name: "XRP",
    price: "0.00010256",
    change: "-1.82%",
  },
  {
    name: "ANT",
    price: "0.00010256",
    change: "+10.38%",
  },
  {
    name: "ETH",
    price: "0.00010256",
    change: "-1.82%",
  },
  {
    name: "BNB",
    price: "0.00010256",
    change: "-1.82%",
  },
  {
    name: "SOL",
    price: "0.00010256",
    change: "-1.82%",
  },
  {
    name: "USDT",
    price: "0.00010256",
    change: "-1.82%",
  },
  {
    name: "XRP",
    price: "0.00010256",
    change: "-1.82%",
  },
  {
    name: "SOL",
    price: "0.00010256",
    change: "-1.82%",
  },
  {
    name: "USDT",
    price: "0.00010256",
    change: "-1.82%",
  },
  {
    name: "XRP",
    price: "0.00010256",
    change: "-1.82%",
  },
];

export const COINS = [
  {
    icon: BitcoinIco,
    name: "Bitcoin",
    symbol: "BTC",
  },
  {
    icon: EthIco,
    name: "Ethereum",
    symbol: "ETH",
  },
  {
    icon: BnbIco,
    name: "Binance",
    symbol: "BNB",
  },
  {
    icon: SolIco,
    name: "Solana",
    symbol: "SOL",
  },
  {
    icon: UsdtIco,
    name: "Tether",
    symbol: "USDT",
  },
  {
    icon: XrpIco,
    name: "Ripple",
    symbol: "XRP",
  },
];

export const PROFIT = [
  {
    date: "22 Jul, 2024",
    cryptoImg: BitcoinIco,
    crypto: "Bitcoin",
    cryptoSymbol: "BTC",
    earned: "0.01 BTC",
    estimated: "0.023 BTC",
  },

  {
    date: "22 Jul, 2024",
    cryptoImg: BitcoinIco,
    crypto: "Bitcoin",
    cryptoSymbol: "BTC",
    earned: "0.01 BTC",
    estimated: "0.023 BTC",
  },
  {
    date: "22 Jul, 2024",
    cryptoImg: BitcoinIco,
    crypto: "Bitcoin",
    cryptoSymbol: "BTC",
    earned: "0.01 BTC",
    estimated: "0.023 BTC",
  },
  {
    date: "22 Jul, 2024",
    cryptoImg: BitcoinIco,
    crypto: "Bitcoin",
    cryptoSymbol: "BTC",
    earned: "0.01 BTC",
    estimated: "0.023 BTC",
  },
  {
    date: "22 Jul, 2024",
    cryptoImg: BitcoinIco,
    crypto: "Bitcoin",
    cryptoSymbol: "BTC",
    earned: "0.01 BTC",
    estimated: "0.023 BTC",
  },
  {
    date: "22 Jul, 2024",
    cryptoImg: BitcoinIco,
    crypto: "Bitcoin",
    cryptoSymbol: "BTC",
    earned: "0.01 BTC",
    estimated: "0.023 BTC",
  },
  {
    date: "22 Jul, 2024",
    cryptoImg: BitcoinIco,
    crypto: "Bitcoin",
    cryptoSymbol: "BTC",
    earned: "0.01 BTC",
    estimated: "0.023 BTC",
  },
  {
    date: "22 Jul, 2024",
    cryptoImg: BitcoinIco,
    crypto: "Bitcoin",
    cryptoSymbol: "BTC",
    earned: "0.01 BTC",
    estimated: "0.023 BTC",
  },
  {
    date: "22 Jul, 2024",
    cryptoImg: BitcoinIco,
    crypto: "Bitcoin",
    cryptoSymbol: "BTC",
    earned: "0.01 BTC",
    estimated: "0.023 BTC",
  },
  {
    date: "22 Jul, 2024",
    cryptoImg: BitcoinIco,
    crypto: "Bitcoin",
    cryptoSymbol: "BTC",
    earned: "0.01 BTC",
    estimated: "0.023 BTC",
  },
  {
    date: "22 Jul, 2024",
    cryptoImg: BitcoinIco,
    crypto: "Bitcoin",
    cryptoSymbol: "BTC",
    earned: "0.01 BTC",
    estimated: "0.023 BTC",
  },
  {
    date: "22 Jul, 2024",
    cryptoImg: BitcoinIco,
    crypto: "Bitcoin",
    cryptoSymbol: "BTC",
    earned: "0.01 BTC",
    estimated: "0.023 BTC",
  },
];

export const HISTORY = [
  {
    date: "22 July, 2024",
    status: "Completed",
    requested: "0.243 BTC",
    fee: "0.005 BTC",
    received: "0.238 BTC",
    withdrawal: "0.243 BTC",
    hash: "0x000...001",
  },
  {
    date: "23 July, 2024",
    status: "In Process",
    requested: "0.450 BTC",
    fee: "0.010 BTC",
    received: "0.440 BTC",
    withdrawal: "0.450 BTC",
    hash: "0x000...002",
  },
  {
    date: "24 July, 2024",
    status: "Failed",
    requested: "0.120 BTC",
    fee: "0.003 BTC",
    received: "0.000 BTC",
    withdrawal: "0.120 BTC",
    hash: "0x000...003",
  },
  {
    date: "25 July, 2024",
    status: "Completed",
    requested: "0.360 BTC",
    fee: "0.007 BTC",
    received: "0.353 BTC",
    withdrawal: "0.360 BTC",
    hash: "0x000...004",
  },
  {
    date: "26 July, 2024",
    status: "In Process",
    requested: "0.528 BTC",
    fee: "0.008 BTC",
    received: "0.520 BTC",
    withdrawal: "0.528 BTC",
    hash: "0x000...005",
  },
  {
    date: "27 July, 2024",
    status: "Failed",
    requested: "0.189 BTC",
    fee: "0.002 BTC",
    received: "0.000 BTC",
    withdrawal: "0.189 BTC",
    hash: "0x000...006",
  },
  {
    date: "28 July, 2024",
    status: "Completed",
    requested: "0.333 BTC",
    fee: "0.006 BTC",
    received: "0.327 BTC",
    withdrawal: "0.333 BTC",
    hash: "0x000...007",
  },
  {
    date: "29 July, 2024",
    status: "In Process",
    requested: "0.244 BTC",
    fee: "0.004 BTC",
    received: "0.240 BTC",
    withdrawal: "0.244 BTC",
    hash: "0x000...008",
  },
  {
    date: "30 July, 2024",
    status: "Failed",
    requested: "0.162 BTC",
    fee: "0.001 BTC",
    received: "0.000 BTC",
    withdrawal: "0.162 BTC",
    hash: "0x000...009",
  },
  {
    date: "31 July, 2024",
    status: "Completed",
    requested: "0.275 BTC",
    fee: "0.005 BTC",
    received: "0.270 BTC",
    withdrawal: "0.275 BTC",
    hash: "0x000...010",
  },
  {
    date: "01 August, 2024",
    status: "In Process",
    requested: "0.498 BTC",
    fee: "0.009 BTC",
    received: "0.489 BTC",
    withdrawal: "0.498 BTC",
    hash: "0x000...011",
  },
  {
    date: "02 August, 2024",
    status: "Failed",
    requested: "0.300 BTC",
    fee: "0.004 BTC",
    received: "0.000 BTC",
    withdrawal: "0.300 BTC",
    hash: "0x000...012",
  },
];

export const LANGUAGES = [
  {
    name: "English",
    code: "en",
  },
  {
    name: "Chinese",
    code: "zh",
  },
  {
    name: "French",
    code: "fr",
  },
  {
    name: "German",
    code: "de",
  },
  {
    name: "Italian",
    code: "it",
  },
  {
    name: "Japanese",
    code: "ja",
  },
  {
    name: "Korean",
    code: "ko",
  },
  {
    name: "Portuguese",
    code: "pt",
  },
  {
    name: "Russian",
    code: "ru",
  },
  {
    name: "Spanish",
    code: "es",
  },
];

export const COIN_STATUS = [
  {
    cryptoIcon: BitcoinIco,
    crypto: "Bitcoin",
    cryptoSymbol: "BTC",
    status: "Active",
    exchangeFrom: CompletedIcon,
    exchangeTo: CompletedIcon,
    fixedRate: NotCompletedIcon,
  },
  {
    cryptoIcon: EtheremIcon,
    crypto: "Etherem",
    cryptoSymbol: "ETC",
    status: "Active",
    exchangeFrom: CompletedIcon,
    exchangeTo: CompletedIcon,
    fixedRate: CompletedIcon,
  },
  {
    cryptoIcon: BinanceIcon,
    crypto: "Binance Coin",
    cryptoSymbol: "BNB",
    status: "Disabled",
    exchangeFrom: CompletedIcon,
    exchangeTo: CompletedIcon,
    fixedRate: CompletedIcon,
  },
  {
    cryptoIcon: UsdIcon,
    crypto: "USD Coin",
    cryptoSymbol: "USDC",
    status: "Active",
    exchangeFrom: CompletedIcon,
    exchangeTo: CompletedIcon,
    fixedRate: CompletedIcon,
  },
  {
    cryptoIcon: RippleIcon,
    crypto: "Ripple",
    cryptoSymbol: "XRP",
    status: "Active",
    exchangeFrom: CompletedIcon,
    exchangeTo: CompletedIcon,
    fixedRate: NotCompletedIcon,
  },
  {
    cryptoIcon: DogeIcon,
    crypto: "Dogecoin",
    cryptoSymbol: "DOGE",
    status: "Disabled",
    exchangeFrom: CompletedIcon,
    exchangeTo: CompletedIcon,
    fixedRate: NotCompletedIcon,
  },
  {
    cryptoIcon: TronIcon,
    crypto: "Tron",
    cryptoSymbol: "TRX",
    status: "Active",
    exchangeFrom: CompletedIcon,
    exchangeTo: CompletedIcon,
    fixedRate: CompletedIcon,
  },
  {
    cryptoIcon: LitecoinIcon,
    crypto: "Litecoin",
    cryptoSymbol: "LTC",
    status: "Active",
    exchangeFrom: CompletedIcon,
    exchangeTo: CompletedIcon,
    fixedRate: CompletedIcon,
  },
  {
    cryptoIcon: StellerIcon,
    crypto: "Stellar",
    cryptoSymbol: "XLM",
    status: "Active",
    exchangeFrom: CompletedIcon,
    exchangeTo: CompletedIcon,
    fixedRate: CompletedIcon,
  },
  {
    cryptoIcon: PolygonIcon,
    crypto: "Polygon",
    cryptoSymbol: "MATIC",
    status: "Active",
    exchangeFrom: CompletedIcon,
    exchangeTo: CompletedIcon,
    fixedRate: CompletedIcon,
  },
  {
    cryptoIcon: UsdtIco,
    crypto: "Tether",
    cryptoSymbol: "USDT",
    status: "Active",
    exchangeFrom: CompletedIcon,
    exchangeTo: CompletedIcon,
    fixedRate: CompletedIcon,
  },
  {
    cryptoIcon: SolIco,
    crypto: "Solana",
    cryptoSymbol: "SOL",
    status: "Active",
    exchangeFrom: CompletedIcon,
    exchangeTo: CompletedIcon,
    fixedRate: CompletedIcon,
  },
];

export const PAIR_STATUS = [
  {
    fromCryptoIcon: BitcoinIco,
    fromCrypto: "Bitcoin",
    fromCryptoSymbol: "BTC",
    status: "Active",

    toCryptoIcon: UsdtIco,
    toCrypto: "Tether",
    toCryptoSymbol: "USDT",
    fixedRate: CompletedIcon,
  },
  {
    fromCryptoIcon: EtheremIcon,
    fromCrypto: "Etherem",
    fromCryptoSymbol: "ETC",
    status: "Active",
    toCryptoIcon: TronIcon,
    toCrypto: "Tron",
    toCryptoSymbol: "TRX",
    fixedRate: CompletedIcon,
  },
  {
    fromCryptoIcon: BinanceIcon,
    fromCrypto: "Binance Coin",
    fromCryptoSymbol: "BNB",
    status: "Disabled",
    toCryptoIcon: EtheremIcon,
    toCrypto: "Etherem",
    toCryptoSymbol: "ETH",
    fixedRate: CompletedIcon,
  },
  {
    fromCryptoIcon: UsdIcon,
    fromCrypto: "USD Coin",
    fromCryptoSymbol: "USDC",
    status: "Active",
    toCryptoIcon: RippleIcon,
    toCrypto: "Ripple",
    toCryptoSymbol: "XRP",
    fixedRate: CompletedIcon,
  },
  {
    fromCryptoIcon: RippleIcon,
    fromCrypto: "Ripple",
    fromCryptoSymbol: "XRP",
    status: "Active",
    toCryptoIcon: LitecoinIcon,
    toCrypto: "Litecoin",
    toCryptoSymbol: "LTC",
    fixedRate: NotCompletedIcon,
  },
  {
    fromCryptoIcon: DogeIcon,
    fromCrypto: "Dogecoin",
    fromCryptoSymbol: "DOGE",
    status: "Disabled",
    toCryptoIcon: SolIco,
    toCrypto: "Solana",
    toCryptoSymbol: "Sol",
    fixedRate: NotCompletedIcon,
  },
  {
    fromCryptoIcon: TronIcon,
    fromCrypto: "Tron",
    fromCryptoSymbol: "TRX",
    status: "Active",
    toCryptoIcon: DogeIcon,
    toCrypto: "Dogecoin",
    toCryptoSymbol: "DOGE",
    fixedRate: CompletedIcon,
  },
  {
    fromCryptoIcon: LitecoinIcon,
    fromCrypto: "Litecoin",
    fromCryptoSymbol: "LTC",
    status: "Active",
    toCryptoIcon: BitcoinIco,
    toCrypto: "Bitcoin",
    toCryptoSymbol: "BTC",
    fixedRate: CompletedIcon,
  },
  {
    fromCryptoIcon: StellerIcon,
    fromCrypto: "Stellar",
    fromCryptoSymbol: "XLM",
    status: "Active",
    toCryptoIcon: BinanceIcon,
    toCrypto: "Binance Coin",
    toCryptoSymbol: "BNB",
    fixedRate: CompletedIcon,
  },
  {
    fromCryptoIcon: PolygonIcon,
    fromCrypto: "Polygon",
    fromCryptoSymbol: "MATIC",
    status: "Active",
    toCryptoIcon: StellerIcon,
    toCrypto: "Stellar",
    toCryptoSymbol: "XLM",
    fixedRate: CompletedIcon,
  },
  {
    fromCryptoIcon: UsdtIco,
    fromCrypto: "Tether",
    fromCryptoSymbol: "USDT",
    status: "Active",
    toCryptoIcon: PolygonIcon,
    toCrypto: "Polygon",
    toCryptoSymbol: "MATIC",
    fixedRate: CompletedIcon,
  },
  {
    fromCryptoIcon: SolIco,
    fromCrypto: "Solana",
    fromCryptoSymbol: "SOL",
    status: "Active",
    toCryptoIcon: UsdIcon,
    toCrypto: "USD Coin",
    toCryptoSymbol: "USDC",
    fixedRate: CompletedIcon,
  },
];

export const ADDRESSES = [
  {
    name: "BTC Wallet",
    walletAddress:
      "03a2cc41af6ae8a5c10713b0d31f3e3b8bdae4d779396839956a9278b4dc9cbb",
  },
  {
    name: "ETH Wallet",
    walletAddress:
      "03a2cc41af6ae8a5c10713b0d31f3e3b8bdae4d779396839956a9278b4dc9cbb",
  },
  {
    name: "SOL Wallet",
    walletAddress:
      "03a2cc41af6ae8a5c10713b0d31f3e3b8bdae4d779396839956a9278b4dc9cbb",
  },
];

export const REF_LINKS = [
  {
    link: "https://extsy.app.link/referral?link_id=4a4f83d630513f",
  },
  { link: "https://extsy.app.link/referral?link_id=4a4f83d630513f" },
  {
    link: "https://extsy.app.link/referral?link_id=4a4f83d630513f",
  },
];
