import { Text } from "@chakra-ui/react";
import React from "react";

function CustomText({
  text,
  family,
  size,
  weight,
  lh,
  ls,
  transform,
  wrap,
  align,
  color,
  opacity,
  onClick,
  cursor,
  noOfLines,
  mt,
  ml,
  pb,
  display,
  mx,
  underline,
  wb,
  bgGradient,
  bgClip,
}: any) {
  return (
    <Text
      fontFamily={family || "Metropolis"}
      fontSize={size || "16px"}
      fontWeight={weight || "400"}
      lineHeight={lh || size || "16px"}
      letterSpacing={ls || "normal"}
      textTransform={transform || "none"}
      whiteSpace={wrap || "none"}
      textAlign={align || "left"}
      color={color || "extsy.neutral300"}
      opacity={opacity || "1"}
      onClick={onClick}
      wordBreak={wb || "none"}
      cursor={cursor ? "pointer" : "auto"}
      noOfLines={noOfLines || "none"}
      mt={mt || "0"}
      ml={ml || "0"}
      pb={pb || "0"}
      mx={mx || "0"}
      display={display || "block"}
      userSelect={cursor ? "none" : "auto"}
      textDecoration={underline ? "underline" : "none"}
      bgGradient={bgGradient || "none"}
      bgClip={bgClip || "none"}
    >
      {text}
    </Text>
  );
}

export default CustomText;
