import {
  Flex,
  Grid,
  GridItem,
  Image,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import ArrowBack from "../../assets/images/black-left.svg";
import ArrowForward from "../../assets/images/black-right.svg";
import EmptyTrxIco from "../../assets/images/empty-trx-ico.svg";
import { HISTORY } from "../../utils/constants";
import FilterIcon from "../../assets/images/filter.svg";
import CustomText from "../../components/text/CustomText";
import GradientText from "../../components/text/GradientText";
import ExtLink from "../../assets/images/externalLink.svg";
import SelectCoin from "../../components/dropdown/selectCoin";
import btcLogo from "../../assets/images/bitcoin-icon.svg";

const Withdrawal = () => {
  const { t } = useTranslation();

  const getBgColor = (status: string) => {
    switch (status) {
      case "Completed":
        return "#56CE77";
      case "In Process":
        return "#FFA333";
      case "Failed":
        return "#FF6574";
      default:
        return "extsy.neutral300";
    }
  };

  return (
    <Grid
      gap="24px"
      // Responsive grid layout
      templateColumns={{
        base: "1fr", // 1 column on mobile
        sm: "repeat(12, 1fr)", // 2 columns on small screens
      }}
    >
      {/* Total USD Equivalent Card */}
      <GridItem
        gridColumn={{
          base: "span 12", // 1 column on mobile
        }}
      >
        <Stack gap={"16px"}>
          <Flex
            display={{ base: "flex", md: "none" }}
            // w={"100%"}
            justify={"flex-end"}
            align={"center"}
            gap={"8px"}
          >
            <CustomText text="Select Coin" size="14px" lh="120%" />
            <SelectCoin img={btcLogo} />
          </Flex>
          <Flex
            p="24px"
            gap="16px"
            flexDir="column"
            bg="extsy.neutral1000"
            borderRadius="24px"
            boxShadow="0px 3px 4px 0px rgba(0, 0, 0, 0.03)"
          >
            <Flex
              align={{ base: "flex-start", md: "center" }}
              gap={"10px"}
              justify="space-between"
              pb="20px"
              flexDirection={{ base: "column", md: "row" }}
            >
              <CustomText
                text={t("Withdrawal History")}
                size="24px"
                weight="700"
                color="extsy.baseWhite"
                family="Titillium Web"
              />
              <Flex
                display={{ base: "none", md: "flex" }}
                // w={"100%"}
                justify={"flex-end"}
                align={"center"}
                gap={"8px"}
              >
                <CustomText text="Select Coin" size="14px" lh="120%" />
                <SelectCoin img={btcLogo} />
              </Flex>
            </Flex>
            {HISTORY?.length === 0 ? (
              <Flex
                minH={"401px"}
                flexDir="column"
                justify="center"
                align="center"
                gap="8px"
              >
                <Image alt="empty" src={EmptyTrxIco} />
                <CustomText
                  text={t("noHistoryFound")}
                  size="14px"
                  color="extsy.neutral400"
                />
              </Flex>
            ) : (
              <TableContainer minH={"363px"}>
                <Table border="1px solid" color="extsy.neutral900">
                  <Thead bg="extsy.neutral900" height="40px">
                    <Tr>
                      <Th
                        borderColor="extsy.neutral900"
                        maxW="283px"
                        minW="160px"
                      >
                        <Flex align="center" gap="4px">
                          <CustomText
                            text={t("Date")}
                            size="13px"
                            color="extsy.baseWhite"
                            weight="500"
                            ls="-0.13px"
                          />
                        </Flex>
                      </Th>
                      <Th
                        borderColor="extsy.neutral900"
                        maxW="160px"
                        minW="160px"
                      >
                        <Flex align="center" gap="4px">
                          <CustomText
                            text={t("Status")}
                            size="13px"
                            color="extsy.baseWhite"
                            weight="500"
                            ls="-0.13px"
                          />
                          <Flex flexDir="column">
                            <Image src={FilterIcon} alt="filter" />
                          </Flex>
                        </Flex>
                      </Th>
                      <Th
                        borderColor="extsy.neutral900"
                        maxW="283px"
                        minW="160px"
                      >
                        <Flex align="center" gap="4px">
                          <CustomText
                            text={t("Requested Amount")}
                            size="13px"
                            color="extsy.baseWhite"
                            weight="500"
                            ls="-0.13px"
                          />
                        </Flex>
                      </Th>
                      <Th
                        borderColor="extsy.neutral900"
                        maxW="283px"
                        minW="160px"
                      >
                        <Flex align="center" gap="4px">
                          <CustomText
                            text={t("Network Fee")}
                            size="13px"
                            color="extsy.baseWhite"
                            weight="500"
                            ls="-0.13px"
                          />
                        </Flex>
                      </Th>
                      <Th
                        borderColor="extsy.neutral900"
                        maxW="283px"
                        minW="160px"
                      >
                        <Flex align="center" gap="4px">
                          <CustomText
                            text={t("Received Amount")}
                            size="13px"
                            color="extsy.baseWhite"
                            weight="500"
                            ls="-0.13px"
                          />
                        </Flex>
                      </Th>
                      <Th
                        borderColor="extsy.neutral900"
                        maxW="283px"
                        minW="160px"
                      >
                        <Flex align="center" gap="4px">
                          <CustomText
                            text={t("Withdrawal Amount")}
                            size="13px"
                            color="extsy.baseWhite"
                            weight="500"
                            ls="-0.13px"
                          />
                        </Flex>
                      </Th>
                      <Th
                        borderColor="extsy.neutral900"
                        maxW="283px"
                        minW="160px"
                      >
                        <Flex align="center" gap="4px">
                          <CustomText
                            text={t("Hash")}
                            size="13px"
                            color="extsy.baseWhite"
                            weight="500"
                            ls="-0.13px"
                          />
                        </Flex>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {HISTORY.map((hist, index) => (
                      <Tr key={index}>
                        <Td
                          borderColor="extsy.neutral900"
                          borderRight="1px solid"
                        >
                          <CustomText text={hist?.date} size="14px" />
                        </Td>
                        <Td
                          borderColor="extsy.neutral900"
                          borderRight="1px solid"
                          borderLeft="1px solid"
                        >
                          <Flex
                            w="90px"
                            h="24px"
                            align="center"
                            justify="center"
                            borderRadius="30px"
                            bg={`${getBgColor(hist?.status)}1A`}
                          >
                            <CustomText
                              text={hist.status}
                              size="10px"
                              color={getBgColor(hist?.status)}
                            />
                          </Flex>
                        </Td>

                        <Td
                          borderColor="extsy.neutral900"
                          borderRight="1px solid"
                        >
                          <CustomText text={hist.requested} size="14px" />
                        </Td>
                        <Td
                          borderColor="extsy.neutral900"
                          borderRight="1px solid"
                        >
                          <CustomText text={hist.fee} size="14px" />
                        </Td>
                        <Td
                          borderColor="extsy.neutral900"
                          borderRight="1px solid"
                        >
                          <CustomText text={hist.received} size="14px" />
                        </Td>
                        <Td
                          borderColor="extsy.neutral900"
                          borderRight="1px solid"
                        >
                          <CustomText text={hist.withdrawal} size="14px" />
                        </Td>
                        <Td
                          borderColor="extsy.neutral900"
                          borderRight="1px solid"
                        >
                          <Flex
                            align={"center"}
                            justify={"space-between"}
                            w={"100%"}
                          >
                            <CustomText text={hist.hash} size="14px" />
                            <Image src={ExtLink} alt="ext link" />
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}

            <Flex
              gap="16px"
              px="20px"
              py="14px"
              align="center"
              justify="flex-end"
            >
              <CustomText
                text={`1-10 ${t("of")} 50`}
                size="13px"
                weight="500"
                color="extsy.neutral300"
              />
              <Flex gap="8px">
                <Image src={ArrowBack} alt="arrow-back" cursor="pointer" />
                <Flex
                  flexDir="column"
                  align="center"
                  justify="center"
                  bg="extsy.neutral900"
                  borderRadius="6px"
                  height="30px"
                  width="30px"
                >
                  <GradientText
                    text="1"
                    size="14px"
                    weight="500"
                    bg="extsy.g1"
                  />
                </Flex>
                <Flex
                  flexDir="column"
                  align="center"
                  justify="center"
                  bg="extsy.neutral900"
                  borderRadius="6px"
                  height="30px"
                  width="30px"
                >
                  <CustomText text="2" size="14px" weight="500" />
                </Flex>
                <Flex
                  flexDir="column"
                  align="center"
                  justify="center"
                  bg="extsy.neutral900"
                  borderRadius="6px"
                  height="30px"
                  width="30px"
                >
                  <CustomText text="3" size="14px" weight="500" />
                </Flex>
                <Image
                  src={ArrowForward}
                  alt="arrow-forward"
                  cursor="pointer"
                />
              </Flex>
            </Flex>
          </Flex>
        </Stack>
      </GridItem>
    </Grid>
  );
};

export default Withdrawal;
