import {
  Box,
  Button,
  Flex,
  Image,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import CustomText from "../text/CustomText";
import { IconArrowNarrowRight, IconCopy, IconEye } from "@tabler/icons-react";
import CustomModal from "../modal/customModal";
import QRCode from "react-qr-code";
import SecurityImg from "../../assets/images/auth-security-Img.svg";
import CustomVerificationInput from "../form/CustomVerificationInput";

const AccountDetail = () => {
  const {
    isOpen: is2FAOpen,
    onOpen: on2FAOpen,
    onClose: on2FAClose,
  } = useDisclosure();
  const {
    isOpen: isVerifyAuthOpen,
    onOpen: onVerifyAuthOpen,
    onClose: onVerifyAuthClose,
  } = useDisclosure();

  const [show, setShow] = React.useState(false);
  const [enabled, setEnabled] = React.useState(false);
  const handleClick = () => setShow(!show);

  return (
    <Stack
      p={"40px"}
      gap={{ base: "24px", md: "48px" }}
      borderRadius={"24px"}
      bg="extsy.neutral1000"
    >
      <CustomText text={"Account Details"} color="extsy.baseWhite" lh="120%" />

      <Stack gap={"14px"} alignSelf={"stretch"}>
        <CustomText
          text={"API Key"}
          color="extsy.baseWhite"
          lh="120%"
          family="Titillium Web"
          size="24px"
          weight="700"
        />
        <Flex align={"center"} gap={"12px"} position={"relative"}>
          {show && (
            <Box
              w={"95%"}
              backdropFilter="blur(3.400px)"
              position={"absolute"}
              bg={"rgba(255,255,255,0.03)"}
              display={"flex"}
              h={{ base: "66px", md: "50px" }}
              border={"1px solid #4E4949"}
              borderTop={{ md: "none" }}
              borderRadius={"4px"}
              top={{ base: "1px", md: "2px" }}
              left={0}
              zIndex={2}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            ></Box>
          )}
          <CustomText
            text="dsgfkewbn gkejrgb kjer gjkbewrkjg ermng erkjg kewj gfewmn gerherb hreher"
            size={{ base: "16px", md: "18px" }}
            align="left"
          />
          <IconEye
            color="#908B8B"
            onClick={handleClick}
            cursor="pointer"
            // width={"16px"}
          />
        </Flex>
      </Stack>
      <Stack
        gap={"14px"}
        alignSelf={"stretch"}
        mt={{ base: "24px", md: "0px" }}
      >
        <CustomText
          text={"E-mail"}
          color="extsy.baseWhite"
          lh="120%"
          family="Titillium Web"
          size="24px"
          weight="700"
        />
        <CustomText
          text={"jhond@gmail.com"}
          color="extsy.neutral500"
          lh="120%"
          family="Power Grotesk"
        />
      </Stack>
      <Stack gap={"8px"} alignSelf={"stretch"}>
        <Flex
          align={{ base: "flex-start", md: "center" }}
          justify={"space-between"}
          flexDirection={{ base: "column", md: "row" }}
        >
          <CustomText
            text={"Two-Factor Authentication"}
            color="extsy.baseWhite"
            lh="120%"
            family="Titillium Web"
            size="24px"
            weight="700"
          />
          {!enabled && (
            <Button
              variant={"unstyled"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"10px"}
              onClick={on2FAOpen}
            >
              <CustomText
                color="extsy.secondary100"
                text="Enable Now"
                family="Power Grotesk"
                ls="0.8px"
                cursor="pointer"
                lh="120%"
              />
              <IconArrowNarrowRight color="#FEF8C3" />
            </Button>
          )}
        </Flex>
        <CustomText
          text={enabled ? "Enabled" : "Disabled"}
          family="Power Grotesk"
          lh="120%"
          ls="0.8px"
          color={enabled ? "#2DC24E" : "extsy.neutral500"}
        />
      </Stack>
      <CustomModal
        isOpen={is2FAOpen}
        onClose={on2FAClose}
        onSubmit={() => {
          on2FAClose();
          onVerifyAuthOpen();
        }}
        headerText={"2 Factor Authentication"}
        btnText={"Enable Now"}
        closeBtntext={"Cancel"}
        bgtopBlur="extsy.primary"
      >
        <Flex p="24px" flexDir="column" gap="24px">
          <CustomText
            text="Security QR Code"
            size="14px"
            color="extsy.baseWhite"
            align="center"
          />
          <Flex
            bg="extsy.bg"
            height="260px"
            width="260px"
            borderRadius="16px"
            align="center"
            justify="center"
            p="18px"
            mx="auto"
          >
            <QRCode value="hey" bgColor="transparent" fgColor="#fafafa" />
          </Flex>
          <CustomText
            text="For more security, enable on authenticator app"
            size="14px"
            color="extsy.baseWhite"
            align="center"
          />
          <Flex mt="8px" gap="10px" flexDir="column">
            <CustomText text="Security Key" size="12px" />
            <Flex
              align="center"
              gap="20px"
              justify="space-between"
              h="56px"
              px="16px"
              bg="extsy.bg"
              borderRadius="12px"
            >
              <CustomText
                text="uodue3849903ns3h3h8s9"
                color="extsy.baseWhite"
              />
              <IconCopy size="24px" color="#F6DBD4" cursor="pointer" />
            </Flex>
          </Flex>
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={isVerifyAuthOpen}
        onClose={onVerifyAuthClose}
        onSubmit={() => {
          setEnabled(true);
          onVerifyAuthClose();
        }}
        headerText={"Verify Authentication"}
        btnText={"Verify Security Code"}
        closeBtntext={"Cancel"}
        bgtopBlur="extsy.primary"
      >
        <Flex p="24px" flexDir="column" align="center" gap="32px">
          <Image src={SecurityImg} alt="security" w="139px" h="120px" />
          <Box py="24px">
            <CustomVerificationInput placeholder={0} w="63px" gap="6px" />
          </Box>
        </Flex>
      </CustomModal>
    </Stack>
  );
};

export default AccountDetail;
