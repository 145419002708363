import { Box, Input } from "@chakra-ui/react";
import React from "react";

function CustomInput({
  type,
  placeholder,
  value,
  onChange,
  rightIco,
  phColor,
}: any) {
  return (
    <Box pos="relative" w={"100%"}>
      <Input
        type={type}
        placeholder={placeholder}
        value={value}
        h={"56px"}
        onChange={onChange}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        w={"100%"}
        // w={w ? w : { base: "100%", sm: "270px", md: "373px" }}
        borderBottom="none"
        borderTop="none"
        borderLeft="none"
        borderRight="none"
        borderRadius="16px"
        color="extsy.baseWhite"
        fontSize="16px"
        fontFamily="Titillium Web"
        pl="0"
        bg={"extsy.neutral900"}
        // pr={rightIco ? "30px" : "auto"}
        p={"9.5px 19.5px"}
        _placeholder={{
          color: phColor || "extsy.neutral700",
          fontSize: "14px",
          fontWeight: "400",
          fontFamily: "Metropolis",
        }}
        _hover={{
          borderBottom: "none",
          borderColor: "extsy.secondary",
        }}
        _focusVisible={{
          border: "none",
          borderBottom: "none",
        }}
      />
      {rightIco && (
        <Box pos="absolute" right="19.5px" top="0" mt="16px">
          {rightIco}
        </Box>
      )}
    </Box>
  );
}

export default CustomInput;
