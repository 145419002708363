import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Image,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import ProfitTable from "./profitTable";
import ProfitPayout from "../../components/card/ProfitPayout";
import CustomText from "../../components/text/CustomText";
import btcLogo from "../../assets/images/bitcoin-icon.svg";
import { IconUser } from "@tabler/icons-react";
import SelectCoin from "../../components/dropdown/selectCoin";
import CustomModal from "../../components/modal/customModal";
import CustomInput from "../../components/form/CustomInput";
import HorizontalLine from "../../assets/images/hor_line.svg";

import CoinDropDown from "../../components/dropdown/CoinDropDown";

const Profit = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Stack mt={"8px"} mb={"50px"}>
      <Flex
        w={"100%"}
        justify={"flex-end"}
        align={"center"}
        mb={"24px"}
        gap={"8px"}
      >
        <CustomText text="Select Coin" size="14px" lh="120%" />
        <SelectCoin img={btcLogo} />
      </Flex>
      <Grid
        gap="24px"
        templateColumns={{
          base: "1fr",
          lg: "repeat(12, 1fr)",
        }}
      >
        <GridItem
          gridColumn={{ base: "span 12", lg: "span 8" }}
          order={{
            base: 2, // Show second on base and md
            lg: 1, // Show first on large screens
          }}
        >
          <ProfitTable />
        </GridItem>
        <GridItem
          gridColumn={{
            base: "span 12",
            lg: "span 4",
          }}
          order={{
            base: 1, // Show second on base and md
            lg: 2, // Show first on large screens
          }}
        >
          <ProfitPayout onClick={onOpen} />
        </GridItem>
      </Grid>
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={() => {
          onClose();
        }}
        bgtopBlur="extsy.primary"
        noCloseFooter={true}
        headerText="Proceed with payout"
        btnText="Confirm payout"
        btnContinueWidth={"400px"}
      >
        <Center
          my={"24px"}
          mx={{ base: "20px", md: "40px" }}
          flexDirection={"column"}
          gap={"32px"}
        >
          <CustomText
            text="Please enter the details below"
            lh="120%"
            size={{ base: "14px", md: "16px" }}
          />
          <Stack gap={"12px"} w={"100%"} align={"flex-start"}>
            <CustomText text="Wallet Address" size="14px" lh="120%" />
            <Flex
              align={"center"}
              gap={"12px"}
              w={"100%"}
              justify={"space-between"}
            >
              <Box w={"74%"}>
                <CustomInput
                  placeholder="Enter Wallet Address"
                  rightIco={<IconUser width={"24px"} color="#665F5F" />}
                />
              </Box>
              <Box>
                <CoinDropDown img={btcLogo} />
              </Box>
            </Flex>
            <Box mt="4px" mb={"12px"} w={"100%"}>
              <Image src={HorizontalLine} alt="line" w={"100%"} />
            </Box>
            <Flex align={"center"} justify={"space-between"} w={"100%"}>
              <CustomText
                text="Total Withdrawal"
                color="extsy.baseWhite"
                lh="18px"
                size={{ base: "14px", md: "16px" }}
              />
              <Flex gap={0} align={"flex-end"}>
                <CustomText
                  text="BTC15"
                  color="extsy.baseWhite"
                  size={{ base: "30px", md: "41px" }}
                  weight="600"
                  // lh="80px"
                />
                <CustomText
                  text=".00"
                  color="extsy.neutral300"
                  size={{ base: "18px", md: "24px" }}
                  weight="700"
                  lh="30px"
                />
              </Flex>
            </Flex>
          </Stack>
        </Center>
      </CustomModal>
    </Stack>
  );
};

export default Profit;
