// import Login from "../pages/auth/Login";
// import SecurityVerification from "../pages/auth/SecurityVerification";
// import LoginSecurityCode from "../pages/auth/LoginSecurityCode";
// import ForgotPassword from "../pages/auth/forgotPassword";
// import VerifyEmail from "../pages/auth/VerifyEmail";
// import EnterEmail from "../pages/auth/EnterEmail";
// import SignUp from "../pages/auth/SignUp";
// import Dashboard from "../pages/dashboard";
// import UpdatePassword from "../pages/auth/UpdatePassword";
// import VirtualCard from "../pages/virtualCard";
// import Swap from "../pages/swap";
// import BalanceTracker from "../pages/balanceTracker";
// import ExtsyPro from "../pages/extsyPro";
// import Trading from "../pages/trading";
// import TradingBalance from "../pages/tradingBalance";
// import TransactionHistory from "../pages/transactionHistory";
// import AmlCheck from "../pages/amlCheck";
// import Raffle from "../pages/raffle";
// import ComingSoon from "../pages/comingSoon";
// import Settings from "../pages/settings";
// import P2P from "../pages/p2p";

import ApiKey from "../pages/auth/ApiKey";
import EmailConfirmed from "../pages/auth/EmailConfirmed";
import Enteremail from "../pages/auth/EnterEmail";
import ForgotPassword from "../pages/auth/forgotPassword";
import Login from "../pages/auth/Login";
import LoginSecurityCode from "../pages/auth/LoginSecurityCode";
import Signup from "../pages/auth/Signup";
import UpdatePassword from "../pages/auth/UpdatePassword";
import VerificationCode from "../pages/auth/VerificationCode";
import VerifyEmail from "../pages/auth/VeriifyEmail";
import CoinStatus from "../pages/coinStatus";
import Dashboard from "../pages/dashboard";
import PairStatus from "../pages/pairStatus";
import Profit from "../pages/profit";
import Settings from "../pages/settings";
import Stats from "../pages/stats";
import Swap from "../pages/swap";
import Widget from "../pages/widget";
import Withdrawal from "../pages/withdrawal";

export const routes = [
  {
    path: "login",
    component: Login,
    isPrivate: false,
  },
  {
    path: "signup",
    component: Signup,
    isPrivate: false,
  },
  {
    path: "verification-code",
    component: VerificationCode,
    isPrivate: false,
  },
  {
    path: "email-confirmed",
    component: EmailConfirmed,
    isPrivate: false,
  },
  {
    path: "security-code",
    component: LoginSecurityCode,
    isPrivate: false,
  },
  {
    path: "api-key",
    component: ApiKey,
    isPrivate: false,
  },
  {
    path: "forgot-password",
    component: ForgotPassword,
    isPrivate: false,
    children: [
      {
        path: "enter-email",
        component: Enteremail,
      },
      {
        path: "verify-email",
        component: VerifyEmail,
      },
      {
        path: "update-password",
        component: UpdatePassword,
      },
    ],
  },
  {
    path: "/",
    component: Dashboard,
    isPrivate: true,
  },
  {
    path: "/swap",
    component: Swap,
    isPrivate: true,
  },
  {
    path: "/stats",
    component: Stats,
    isPrivate: true,
  },
  {
    path: "/profit",
    component: Profit,
    isPrivate: true,
  },
  {
    path: "/withdrawal",
    component: Withdrawal,
    isPrivate: true,
  },
  {
    path: "/coin-status",
    component: CoinStatus,
    isPrivate: true,
  },
  {
    path: "/pair-status",
    component: PairStatus,
    isPrivate: true,
  },
  {
    path: "/settings",
    component: Settings,
    isPrivate: true,
  },
  {
    path: "/widget",
    component: Widget,
    isPrivate: true,
  },
];
