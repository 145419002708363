import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import CustomText from "../text/CustomText";
import { LANGUAGES } from "../../utils/constants";
import TableSearchInput from "../form/TableSearchInput";

function Languages() {
  return (
    <Box
      pos="absolute"
      // maxW="513px"
      w="254px"
      p="16px"
      borderRadius="24px"
      border="1px solid"
      borderColor="extsy.neutral900"
      bg="#131313"
      top="0"
      mt="50px"
      right="0"
      zIndex="10000"
    >
      <Flex flexDir="column" gap="24px">
        <CustomText text={"Language"} color="extsy.baseWhite" weight="600" />
        <TableSearchInput placeholder="Search language" minW="222px" />
        <Box overflowY={"auto"} maxH="283px">
          <Flex flexDir="column" gap="2px">
            {LANGUAGES?.map((lang, index) => (
              <Flex p="2px 8px 16px 8px" cursor="pointer" key={index}>
                <CustomText text={lang?.name} color="extsy.baseWhite" cursor />
              </Flex>
            ))}
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}

export default Languages;
