import {
  Box,
  Flex,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IconCalendar } from "@tabler/icons-react";
import ArrowBack from "../../assets/images/black-left.svg";
import ArrowForward from "../../assets/images/black-right.svg";
import EmptyTrxIco from "../../assets/images/empty-trx-ico.svg";
import { STATS } from "../../utils/constants";
import CustomText from "../../components/text/CustomText";
import GradientText from "../../components/text/GradientText";
import PrimaryButton from "../../components/button/primaryButton";
import CustomCalendar from "../../components/form/CustomCalendar";
import TableSearchInput from "../../components/form/TableSearchInput";

const StatsTable = () => {
  const { t } = useTranslation();

  const [dateRange, setDateRange] = useState([new Date(), new Date()]); // For range selection
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const formatDateRange = (range: [Date, Date]) => {
    const options: any = { day: "2-digit", month: "short", year: "numeric" };
    const startDate = range[0]
      .toLocaleDateString("en-US", options)
      .replace(/,/g, "");
    const endDate = range[1]
      .toLocaleDateString("en-US", options)
      .replace(/,/g, "");
    return `${startDate} - ${endDate}`;
  };

  return (
    <Flex
      p="24px"
      gap="16px"
      flexDir="column"
      bg="extsy.neutral1000"
      borderRadius="24px"
      boxShadow="0px 3px 4px 0px rgba(0, 0, 0, 0.03)"
    >
      <Flex
        align={{ base: "flex-start", md: "center" }}
        gap={"10px"}
        justify="space-between"
        pb="20px"
        flexDirection={{ base: "column", md: "row" }}
      >
        <Flex align={"center"} justify={"space-between"} w={"100%"}>
          <CustomText
            text={t("Stats")}
            size="24px"
            weight="700"
            color="extsy.baseWhite"
            family="Titillium Web"
          />
          <Box display={{ base: "block", md: "none" }}>
            <PrimaryButton
              title="Export .CSV"
              size="14px"
              color="extsy.bg"
              height="38px"
              maxW="134px"
            />
          </Box>
        </Flex>
        <Flex
          align={{ md: "center" }}
          gap="10px"
          flexDirection={{ base: "column-reverse", md: "row" }}
          w={{ base: "100%", md: "fit-content" }}
        >
          <TableSearchInput
            placeholder={t("Search for exchange ID, Address or tx hash")}
            minW={{ base: "100%", md: "336px" }}
          />

          <>
            <Box pos="relative">
              <Flex
                cursor="pointer"
                gap="16px"
                align="center"
                borderRadius="666px"
                minW={"230px"}
                bg="extsy.neutral900"
                height="38px"
                ps="24px"
                pe="10px"
                onClick={() => setIsCalendarOpen(!isCalendarOpen)}
              >
                <CustomText
                  cursor
                  text={formatDateRange(dateRange as [Date, Date])} // Display selected date range
                  size="12px"
                  color="extsy.baseWhite"
                />
                <IconCalendar size="16px" color="#fafafa" />
              </Flex>
              <CustomCalendar
                isCalendarOpen={isCalendarOpen}
                value={dateRange}
                onChange={(range: any) => {
                  setDateRange(range); // Update the date range
                  setIsCalendarOpen(false); // Close calendar
                }}
                isRangePicker={true} // Enable range picker
              />
            </Box>
            <Box display={{ base: "none", md: "block" }}>
              <PrimaryButton
                title="Export .CSV"
                size="14px"
                color="extsy.bg"
                height="38px"
                maxW="134px"
              />
            </Box>
          </>
        </Flex>
      </Flex>
      {STATS?.length === 0 ? (
        <Flex
          minH={"401px"}
          flexDir="column"
          justify="center"
          align="center"
          gap="8px"
        >
          <Image alt="empty" src={EmptyTrxIco} />
          <CustomText
            text={t("noHistoryFound")}
            size="14px"
            color="extsy.neutral400"
          />
        </Flex>
      ) : (
        <TableContainer minH={"363px"}>
          <Table border="1px solid" color="extsy.neutral900">
            <Thead bg="extsy.neutral900" height="40px">
              <Tr>
                <Th borderColor="extsy.neutral900" maxW="283px" minW="160px">
                  <Flex align="center" gap="4px">
                    <CustomText
                      text={t("Date")}
                      size="13px"
                      color="extsy.baseWhite"
                      weight="500"
                      ls="-0.13px"
                    />
                  </Flex>
                </Th>
                <Th borderColor="extsy.neutral900" maxW="160px" minW="160px">
                  <Flex align="center" gap="4px">
                    <CustomText
                      text={t("Created")}
                      size="13px"
                      color="extsy.baseWhite"
                      weight="500"
                      ls="-0.13px"
                    />
                  </Flex>
                </Th>
                <Th borderColor="extsy.neutral900" maxW="283px" minW="160px">
                  <Flex align="center" gap="4px">
                    <CustomText
                      text={t("Waiting")}
                      size="13px"
                      color="extsy.baseWhite"
                      weight="500"
                      ls="-0.13px"
                    />
                  </Flex>
                </Th>
                <Th borderColor="extsy.neutral900" maxW="283px" minW="160px">
                  <Flex align="center" gap="4px">
                    <CustomText
                      text={t("Finished")}
                      size="13px"
                      color="extsy.bgCardTopBlur"
                      weight="500"
                      ls="-0.13px"
                    />
                  </Flex>
                </Th>
                <Th borderColor="extsy.neutral900" maxW="283px" minW="160px">
                  <Flex align="center" gap="4px">
                    <CustomText
                      text={t("Failed")}
                      size="13px"
                      color="#ED3D4E"
                      weight="500"
                      ls="-0.13px"
                    />
                  </Flex>
                </Th>
                <Th borderColor="extsy.neutral900" maxW="283px" minW="160px">
                  <Flex align="center" gap="4px">
                    <CustomText
                      text={t("Turnover, BTC")}
                      size="13px"
                      color="extsy.baseWhite"
                      weight="500"
                      ls="-0.13px"
                    />
                  </Flex>
                </Th>
                <Th borderColor="extsy.neutral900" maxW="283px" minW="160px">
                  <Flex align="center" gap="4px">
                    <CustomText
                      text={t("Affiliate Reward, BTC")}
                      size="13px"
                      color="extsy.baseWhite"
                      weight="500"
                      ls="-0.13px"
                    />
                  </Flex>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {STATS.map((stat, index) => (
                <Tr
                  key={index}
                  bg={index === 0 ? "#272727" : "transparent"} // Apply bg color for index 0
                >
                  <Td
                    borderColor="extsy.neutral900"
                    borderRight="1px solid"
                    borderLeft="1px solid"
                  >
                    <Flex
                      w="90px"
                      h="24px"
                      align="center"
                      justify="center"
                      borderRadius="30px"
                      ml={index === 0 ? "-30px" : "0px"}
                    >
                      <CustomText text={stat?.date} size="14px" />
                    </Flex>
                  </Td>
                  <Td borderColor="extsy.neutral900" borderRight="1px solid">
                    <CustomText text={stat?.Created} size="14px" />
                  </Td>
                  <Td borderColor="extsy.neutral900" borderRight="1px solid">
                    <CustomText text={stat.Waiting} size="14px" />
                  </Td>
                  <Td borderColor="extsy.neutral900" borderRight="1px solid">
                    <CustomText
                      text={stat.Finished}
                      size="14px"
                      color="extsy.bgCardTopBlur"
                    />
                  </Td>
                  <Td borderColor="extsy.neutral900" borderRight="1px solid">
                    <CustomText
                      text={stat.Failed}
                      size="14px"
                      color="#ED3D4E"
                    />
                  </Td>
                  <Td borderColor="extsy.neutral900" borderRight="1px solid">
                    <CustomText text={stat.turnover} size="14px" />
                  </Td>
                  <Td borderColor="extsy.neutral900" borderRight="1px solid">
                    <CustomText text={stat.reward} size="14px" />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      <Flex gap="16px" px="20px" py="14px" align="center" justify="flex-end">
        <CustomText
          text={`1-10 ${t("of")} 50`}
          size="13px"
          weight="500"
          color="extsy.neutral300"
        />
        <Flex gap="8px">
          <Image src={ArrowBack} alt="arrow-back" cursor="pointer" />
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <GradientText text="1" size="14px" weight="500" bg="extsy.g1" />
          </Flex>
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <CustomText text="2" size="14px" weight="500" />
          </Flex>
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <CustomText text="3" size="14px" weight="500" />
          </Flex>
          <Image src={ArrowForward} alt="arrow-forward" cursor="pointer" />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default StatsTable;
