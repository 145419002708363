import { Box, Center, Image } from "@chakra-ui/react";
import React from "react";
import OtpTickIcon from "../../assets/images/otp-tick-icon.svg";
import CustomText from "../../components/text/CustomText";
import PrimaryButton from "../../components/button/primaryButton";
import { useNavigate } from "react-router-dom";

const EmailConfirmed = () => {
  const navigate = useNavigate();
  return (
    <Center
      p={"56px 40px"}
      flexDirection={"column"}
      borderRadius={"24px"}
      bg={"rgba(255,255,255,0.04)"}
      backdropFilter={"blur(28px)"}
      w={"100%"}
      maxW={{ base: "358px", md: "600px" }}
      //   mb={"60px"}
      gap={"32px"}
    >
      <Image src={OtpTickIcon} alt="tick" w={{ base: "55px", md: "80px" }} />
      <CustomText
        text="Email Confirmed"
        color="extsy.baseWhite"
        family="Power Grotesk"
        size="24px"
      />
      {/* <Flex align={"center"} gap={"5px"} w={"100%"}> */}
      <CustomText
        text={"Please login to your account"}
        size={{ base: "14px", md: "16px" }}
        align="center"
      />
      <Box w={{ base: "100%", md: "75%" }} mt={{ base: "90px", md: "0px" }}>
        <PrimaryButton title="Login" onClick={() => navigate("/auth/login")} />
      </Box>
    </Center>
  );
};

export default EmailConfirmed;
